import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { toast } from 'react-toastify';

import StarIcon from '@/assets/images/project/star.inline.svg';
import { useTranslation } from '@/i18n';
import { DialogContext } from '@/libs/dialogClient/dialogProvider';
import { UserContext } from '@/libs/userClient/userProvider';
import portForwardService from '@/services/portForwards';
import { projectService } from '@/services/projectService';
import templateService from '@/services/templateService';
import { Locale } from '@/services/templateService/ITemplateService';
import { useRole } from '@/utils/hooks/useRole';

import { Button, EButtonType } from '../common/Button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter
} from '../common/Dialog';
import { IOption, Select } from '../common/Select';

export function SaveAsTemplate() {
  const { isOpenSaveAsTemplate, setIsOpenSaveAsTemplate } =
    useContext(DialogContext);
  const {
    selectProjects,
    reloadProjectListNumber,
    setReloadProjectListNumber
  } = useContext(UserContext);
  const { i18n, t } = useTranslation();
  const { isGlobalAdmin, isConfluxAdmin } = useRole();

  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const originTypeDropDownOptions: IOption[] = useMemo(() => {
    const options = [{ value: 'privateTemplate', label: 'Private Template' }];

    if (isGlobalAdmin || isConfluxAdmin) {
      options.push({ value: 'publicTemplate', label: 'Public Template' });
    }

    return options;
  }, [isConfluxAdmin, isGlobalAdmin]);

  const [selectOption, setSelectOption] = useState<IOption>(
    originTypeDropDownOptions[0]
  );

  const doSaveAsTemplate = useCallback(async () => {
    if (!name || !description || !selectOption) return;
    try {
      setLoading(true);
      if (selectProjects[0].running) {
        const portForwardData = await portForwardService.getOpensumiPortForward(
          selectProjects[0].workspaceId
        );
        await projectService.packageWorkspace(
          `https://${portForwardData.host}`
        );
      }
      await templateService.createTemplate(
        selectProjects[0].workspaceId,
        {
          isPublic: selectOption.value === 'publicTemplate',
          isDefault: false,
          chain: selectProjects[0].fromChain,
          name,
          description,
          displayName: name,
          version: '0.0.1',
          icon: 'https://marketplace-beta.media.chainide.com/templates/default_icon.svg',
          readmeFile:
            'https://marketplace-beta.media.chainide.com/templates/default_readme.md',
          defaultReadmePath: selectProjects[0].defaultReadmePath || '/',
          defaultOrder: 0
        },
        i18n.language === 'en-US' ? Locale.EN : Locale.ZH
      );
      setReloadProjectListNumber(reloadProjectListNumber + 1);
      toast.success(t('save_as_template_success'));
    } catch (e) {
      toast.error((e as Error).message);
    } finally {
      setLoading(false);
      setIsOpenSaveAsTemplate(false);
    }
  }, [
    description,
    i18n.language,
    name,
    reloadProjectListNumber,
    selectOption,
    selectProjects,
    setIsOpenSaveAsTemplate,
    setReloadProjectListNumber,
    t
  ]);

  useEffect(() => {
    setName(selectProjects[0]?.workspaceName);
    setDescription(selectProjects[0]?.description ?? '');
  }, [selectProjects]);

  return (
    <Dialog open={isOpenSaveAsTemplate} onOpenChange={setIsOpenSaveAsTemplate}>
      <DialogContent>
        <div className="flex flex-col items-center justify-center">
          <div className="mb-6 text-center text-xl font-medium leading-[1.35]">
            {i18n.t('Save_as_template')}
          </div>

          <div className="flex w-full flex-col gap-y-2">
            <label className="flex items-start">
              <div className="mt-1">{i18n.t('template_name')}</div> <StarIcon />
            </label>
            <input
              className="rounded-[6px] border-0 bg-[#2D343E] py-3 px-4 outline-none placeholder:text-[#6F7780]"
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
          </div>

          <div className="mt-4 flex w-full flex-col gap-y-2">
            <label className="flex items-start">
              <div className="mt-1">{i18n.t('template_desc')}</div> <StarIcon />
            </label>
            <textarea
              className="min-h-[90px] rounded-[6px] border-0 bg-[#2D343E] py-3 px-4 outline-none"
              value={description}
              onChange={(event) => setDescription(event.target.value)}
            />
          </div>

          <div className="mt-4 flex w-full flex-col gap-y-2">
            <label className="flex items-start">
              <div className="mt-1">{i18n.t('template_type')}</div> <StarIcon />
            </label>
            <Select
              className="w-full"
              selectOption={selectOption}
              onChange={setSelectOption}
              options={originTypeDropDownOptions}
            />
          </div>
        </div>
        <DialogFooter className="mt-8">
          <DialogClose asChild={true}>
            <Button
              type={EButtonType.GHOST}
              className="h-10 w-full font-medium sm:w-[192px]"
            >
              {i18n.t('cancel')}
            </Button>
          </DialogClose>
          <Button
            loading={loading}
            type={EButtonType.PRIMARY}
            buttonType="submit"
            className="h-10 w-full font-medium sm:w-[192px]"
            onClick={doSaveAsTemplate}
          >
            {i18n.t('confirm')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
