import React, { ReactNode } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { v4 as uuid } from 'uuid';

import { EmptyProjectCard } from '../pages/projects/EmptyProjectCard';
import { Loader } from './Loader';
import NoData from './NoData';

interface ScrollListProps extends React.HTMLProps<InfiniteScroll> {
  className?: string;
  children: React.ReactNode;
  /**
   * A callback for when more items are requested by the user. Page param is next page index.
   */
  loadMore: (page: number) => void;
  hasMore?: boolean;
  threshold?: number;
  /**
   * Loader component for indicating "loading more".
   */
  loader?: React.ReactElement;
  /**
   * flag to tell if the list is empty
   */
  isEmpty?: boolean;
  /**
   * flag to tell if the list is loading
   */
  isLoading?: boolean;
  emptyTitle?: string;
  emptyIcon?: boolean;
  emptyDescription?: string;
  useWindow?: boolean;
  extraComponent?: ReactNode;
}

const ScrollList: React.FC<ScrollListProps> = (props: ScrollListProps) => {
  const {
    className,
    children,
    loadMore,
    hasMore,
    threshold,
    loader,
    isEmpty,
    isLoading,
    // emptyTitle,
    // emptyIcon,
    // emptyDescription,
    extraComponent,
    // useWindow = true,
    ...rest
  } = props;

  return (
    <div>
      <Loader
        isLoading={isLoading || false}
        loadingPlaceholder={loader}
        loadingClassName={className}
        className="h-auto"
        extraComponent={extraComponent}
        style={rest.style ? { ...rest.style } : {}}
      >
        {isEmpty ? (
          <div>
            <div className="h-[215px] max-w-[280px]">{extraComponent}</div>
            {/* <NoData
              className="mb-[100px] mt-[100px] lg:mb-[100px]"
              icon={emptyIcon}
              title={emptyTitle || ''}
              desc={emptyDescription}
            /> */}
          </div>
        ) : (
          <InfiniteScroll
            key={uuid()}
            loadMore={loadMore}
            hasMore={hasMore}
            threshold={threshold}
            loader={loader}
            className={className}
            useWindow={false}
            {...rest}
          >
            {children}
          </InfiniteScroll>
        )}
      </Loader>
    </div>
  );
};

ScrollList.defaultProps = {
  hasMore: false,
  threshold: 250,
  isEmpty: false,
  emptyIcon: false,
  isLoading: false
};

export default ScrollList;
