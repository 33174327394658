import cn from 'classnames';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useClickAway, useMount } from 'react-use';

import LogoSVG from '@/assets/images/common/logo.inline.svg';
import LogoutSVG from '@/assets/images/header/logout_icon.inline.svg';
import userIcon from '@/assets/images/header/user_icon_inline.png';
import { useTranslation } from '@/i18n';
import { DialogContext } from '@/libs/dialogClient/dialogProvider';
import { initUserInfo, UserContext } from '@/libs/userClient/userProvider';
import { logOut } from '@/services/request';
import { emitter, emitterEvent } from '@/utils/event';
import { EStorageKeys, storageUtil } from '@/utils/storage';
import { IUserInfo } from '@/utils/tokenUtils';

import { Button, EButtonType } from './Button';

interface HeaderProps {
  className?: string;
}

export function Header(props: HeaderProps) {
  const { className } = props;
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement | null>(null);
  const { setIsOpenLoginDialog } = useContext(DialogContext);
  const { userInfo, setUserInfo, setPrivateTemplateList } =
    useContext(UserContext);
  const [show, setShow] = useState(false);
  useClickAway(ref, () => {
    setShow(false);
  });

  useMount(() => {
    const storageInfo = storageUtil.get(EStorageKeys.ACCESS_TOKEN);
    if (!storageInfo) {
      logOut();
      setUserInfo(initUserInfo);
      setIsOpenLoginDialog(true);
    }

    const info = storageUtil.get<IUserInfo>(EStorageKeys.USER_INFO);
    if (!info || !info.userId) {
      setUserInfo(initUserInfo);
      setIsOpenLoginDialog(true);
    } else if (!userInfo.userId) {
      setUserInfo(info);
    }
  });

  useEffect(() => {
    emitter.on(emitterEvent.RefreshTokenExpired, () => {
      console.log('refresh token expires');
      toast.warning(t('refresh_token_expired_message'));
      setUserInfo(initUserInfo);
    });
    return () => {
      emitter.off(emitterEvent.RefreshTokenExpired);
    };
  }, [setUserInfo, t]);

  return (
    <>
      <header
        className={cn(
          className,
          'box-border flex h-[61px] flex-row items-center justify-between border-b-[1px] border-solid border-[#2b2c34] px-4'
        )}
      >
        <LogoSVG width={115} height={33} />
        <div className="flex h-full flex-row items-center gap-x-3">
          {!userInfo.userId && (
            <Button
              type={EButtonType.PRIMARY}
              className="h-[33px] w-[104px]"
              onClick={() => setIsOpenLoginDialog(true)}
            >
              {t('sign_in')}
            </Button>
          )}
          {userInfo.userId && (
            <div
              ref={ref}
              className="relative flex cursor-pointer items-center gap-x-2"
              onClick={() => {
                setShow(!show);
              }}
            >
              <div
                className=" flex h-[30px] w-[30px] select-none items-center justify-center rounded-[100%] text-[16px] font-[600] uppercase"
                style={{
                  background: `url(${userIcon})`
                }}
              >
                {userInfo.username.charAt(0)}
              </div>
              <div className="max-w-[60px] truncate">{userInfo.username}</div>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.9999 7.99545C16.0004 8.12349 15.9721 8.25 15.9172 8.36568C15.8624 8.48137 15.7823 8.58329 15.6828 8.66395L10.5405 12.8036C10.3871 12.9296 10.1947 12.9985 9.99622 12.9985C9.7977 12.9985 9.60534 12.9296 9.45199 12.8036L4.30962 8.51825C4.13459 8.37278 4.02452 8.16373 4.00363 7.9371C3.98273 7.71047 4.05272 7.48482 4.1982 7.3098C4.34367 7.13477 4.55272 7.0247 4.77935 7.00381C5.00598 6.98291 5.23163 7.0529 5.40665 7.19838L10.0005 11.0294L14.5944 7.32694C14.7202 7.22213 14.8734 7.15556 15.0358 7.13509C15.1983 7.11463 15.3632 7.14113 15.5111 7.21145C15.6589 7.28178 15.7835 7.39299 15.8702 7.53193C15.9568 7.67087 16.0019 7.83172 15.9999 7.99545Z"
                  fill="white"
                  className={cn(
                    'origin-center duration-300',
                    show && 'rotate-180'
                  )}
                />
              </svg>

              <ul
                className={cn(
                  'absolute top-9 right-0 z-[90] w-[120px] flex-col gap-y-1 overflow-y-auto rounded-[6px] bg-[#303243] py-[5px] text-[16px] text-[#C3C5DD] duration-300 hover:bg-[#3272C7] hover:text-[#FFF]',
                  show
                    ? 'z-10 flex opacity-100 shadow-common'
                    : '-z-10 hidden opacity-0'
                )}
              >
                <li
                  className="flex cursor-pointer select-none items-center gap-x-[6px] overflow-y-auto pl-4 text-[14px] "
                  onClick={() => {
                    logOut();
                    setUserInfo(initUserInfo);
                    setPrivateTemplateList([]);
                    toast.success(t('log_out_successful'));
                  }}
                >
                  <LogoutSVG />
                  {t('log_out')}
                </li>
              </ul>
            </div>
          )}
        </div>
      </header>
    </>
  );
}
