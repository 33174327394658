/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/no-unsafe-return */

import { getDeviceId } from '@/utils/hooks/useGetDeviceId';

import request, {
  deleteWithForm,
  postWithForm,
  putWithForm
} from '../../request';
import {
  EProjectAnonymousStatus,
  IProject,
  IProjectQueryParams,
  IProjectQueryResponse,
  IProjectService,
  ProjectType,
  SourceType
} from '../IProjectService';

interface IBackendWorkspace {
  workspaceId: string;
  deviceId: string;
  userId: string;
  type: string;
  workspaceName: string;
  fromChain: string;
  defaultReadmePath: string;
  lastActiveTime: string | number;
  createTime: string | number;
  thumbnail: string;
  running: boolean;
  terminal: boolean;
  pluginInfo?: string;
  template?: string;
  diskUsage?: string;
  hardLimit?: string;
  softLimit?: string;
  description?: string;
  anonymous?: 'true' | 'false';
}

export class SandboxProjectServiceImpl implements IProjectService {
  private getUserId() {
    return getDeviceId();
  }

  async createProjectByTemplate(
    deviceId: string,
    fromChain: string,
    templateId: string,
    cpuQuota?: string,
    diskQuota?: string,
    memQuota?: string
  ): Promise<string> {
    const { data } = await request('/api/workspaces', {
      method: 'post',
      requestType: 'json',
      data: {
        deviceId,
        sourceType: SourceType.TEMPLATE,
        chainName: fromChain,
        templateId,
        cpuQuota,
        diskQuota,
        memQuota
      }
    });
    return data;
  }

  async checkProjectExisted(
    source: string,
    partnerId: string
  ): Promise<boolean> {
    const { data } = await request('/api/workspace/source', {
      method: 'get',
      params: {
        source,
        partnerId
      }
    });
    return data;
  }

  async createProjectByGit(
    deviceId: string,
    chain: string,
    url: string,
    branch: string,
    githubAuthToken?: string,
    cpuQuota?: string,
    diskQuota?: string,
    memQuota?: string
  ): Promise<string> {
    const { data } = await request('/api/workspaces', {
      method: 'post',
      requestType: 'json',
      data: {
        deviceId,
        sourceType: SourceType.GIT,
        chainName: chain,
        repoBranch: branch,
        githubAuthToken,
        repoUrl: url,
        cpuQuota,
        diskQuota,
        memQuota
      }
    });
    return data;
  }

  async createProjectByGist(
    deviceId: string,
    chain: string,
    gistUrl: string,
    githubAuthToken: string,
    cpuQuota?: string,
    diskQuota?: string,
    memQuota?: string
  ): Promise<string> {
    const { data } = await request('/api/workspaces', {
      method: 'post',
      requestType: 'json',
      data: {
        deviceId,
        sourceType: SourceType.GIST,
        chainName: chain,
        gistUrl,
        githubAuthToken,
        cpuQuota,
        diskQuota,
        memQuota
      }
    });
    return data;
  }

  async createProjectByZip(
    zipFileName: string,
    deviceId: string,
    chain: string,
    cpuQuota?: string,
    diskQuota?: string,
    memQuota?: string
  ): Promise<string> {
    const { data } = await request(`/api/workspaces`, {
      method: 'post',
      requestType: 'json',
      data: {
        deviceId,
        sourceType: SourceType.ZIP,
        chainName: chain,
        zipFileName,
        cpuQuota,
        diskQuota,
        memQuota
      }
    });
    return data;
  }

  async uploadFile(file: File) {
    const { data } = await postWithForm(
      '/api/files',
      {
        file
      },
      6000
    );
    return data;
  }

  async getProjects(deviceId: string): Promise<IProject[]> {
    const { data } = await request.get<{ data: IBackendWorkspace[] }>(
      '/api/workspaces',
      {
        params: { deviceId }
      }
    );
    data.sort(
      (p1: IBackendWorkspace, p2: IBackendWorkspace) =>
        +p2.createTime - +p1.createTime
    );

    const newData = data.filter((item: IBackendWorkspace) => {
      const { type } = item;
      if (
        type === ProjectType.PRIVATE_TEMPLATE ||
        type === ProjectType.PUBLIC_TEMPLATE
      ) {
        return false;
      }
      return true;
    });
    return newData.map(this.transformToFrontendProject);
  }

  async getProject(id: string): Promise<IProject | null> {
    const { data } = await request.get(`/api/workspaces/${id}`);
    if (data) return this.transformToFrontendProject(data);
    return null;
  }

  async anonymousTransfer2User(id: string): Promise<IProject | null> {
    const { data } = await request('/api/workspace/anonymousTransfer2User', {
      method: 'put',
      requestType: 'form',
      data: {
        workspaceId: id
      }
    });
    if (data) return data;
    return null;
  }

  async updateProjectInfo(
    projectId: string,
    workspaceName: string,
    description: string
  ): Promise<void> {
    await request(`/api/workspaces/${projectId}`, {
      method: 'put',
      requestType: 'json',
      data: {
        workspaceName,
        description
      }
    });
  }

  async setDefaultReadmePath(id: string, path?: string): Promise<void> {
    await putWithForm('/api/workspace/updateWorkspaceInfo', {
      workspaceId: id,
      key: 'defaultReadmePath',
      value: path || ''
    });
  }

  async removeProject(workspaceId: string): Promise<void> {
    await deleteWithForm(`/api/workspaces/${workspaceId}`, {
      workspaceId
    });
  }

  async removeProjects(workspaceIds: string[]): Promise<void> {
    await request('/api/workspaces', {
      method: 'delete',
      requestType: 'json',
      data: {
        workspaceIds
      }
    });
  }

  async packageWorkspace(url: string): Promise<string> {
    const data = await request(`/workspace/package`, {
      method: 'get',
      requestType: 'form',
      prefix: `${url}`
    });
    return data;
  }

  async queryWorkspacePage(
    params: IProjectQueryParams
  ): Promise<IProjectQueryResponse> {
    const { data } = await request('/api/workspaces/query', {
      method: 'get',
      requestType: 'form',
      params: {
        size: '18',
        ...params
      }
    });
    return data;
  }

  async getWorkspaceInfo(workspaceId: string): Promise<IProject> {
    const { data } = await request(`/api/workspaces/${workspaceId}`, {
      method: 'get',
      params: {
        workspaceId
      }
    });
    return data;
  }

  transformToFrontendProject(workspace: IBackendWorkspace): IProject {
    return {
      workspaceId: workspace.workspaceId,
      workspaceName: workspace.workspaceName,
      deviceId: workspace.deviceId,
      userId: workspace.userId,
      type: workspace.type as ProjectType,
      fromChain: workspace.fromChain,
      createTime: workspace.createTime,
      thumbnail: workspace.thumbnail,
      defaultReadmePath: workspace.defaultReadmePath,
      pluginInfo: workspace.pluginInfo,
      lastActiveTime: workspace.lastActiveTime,
      template: workspace.template,
      diskUsage: workspace.diskUsage,
      hardLimit: workspace.hardLimit,
      softLimit: workspace.softLimit,
      running: workspace.running,
      terminal: workspace.terminal,
      description: workspace.description,
      anonymous: workspace.anonymous as EProjectAnonymousStatus
    };
  }
}

function transformDateString(number: string | number): string {
  if (number) {
    try {
      const num = parseInt(number.toString());
      const date = new Date(num);
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    } catch (e) {
      /* ignore */
    }
  }
  return '';
}
