/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import request, { putWithForm } from '../request';
import { ISandboxService, ISandboxStatus } from './ISandboxService';

export class SandboxServiceImpl implements ISandboxService {
  async getSandboxStatus(workspaceId: string): Promise<ISandboxStatus> {
    const { data } = await request(
      `/api/workspaces/${workspaceId}/sandbox/status`,
      {
        method: 'get',
        params: {
          workspaceId
        }
      }
    );
    return data as ISandboxStatus;
  }

  async startupSandbox(workspaceId: string): Promise<void> {
    await putWithForm(`/api/workspaces/${workspaceId}/sandbox/startup`, {
      workspaceId
    });
  }

  async shutdownSandbox(workspaceId: string): Promise<void> {
    await putWithForm(`/api/workspaces/${workspaceId}/sandbox/shutdown`, {
      workspaceId
    });
  }

  async pinSandbox(workspaceId: string): Promise<void> {
    await putWithForm(`/api/workspaces/${workspaceId}/sandbox/pin`, {
      workspaceId
    });
  }

  async unpinSandbox(workspaceId: string): Promise<void> {
    await putWithForm(`/api/workspaces/${workspaceId}/sandbox/unpin`, {
      workspaceId
    });
  }
}
