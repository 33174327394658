import cn from 'classnames';
import React, { ReactNode, useContext, useEffect } from 'react';
import { useLocation, useMount } from 'react-use';

import { UserContext } from '@/libs/userClient/userProvider';
import { callbackPath } from '@/pages/authCallbackCenter';
import { accountService } from '@/services/api/accountService';
import { useSize } from '@/utils/hooks/useSize';
import { loadingUtils } from '@/utils/loadingUtils';

import { EditProjectInfoDialog } from '../dialogs/EditProjectInfoDialog';
import { LoginDialog } from '../dialogs/LoginDialog';
import { SaveAsTemplate } from '../dialogs/SaveAsTemplate';
import { Header } from './Header';
import { SideBar } from './SideBar';

interface LayoutProps {
  [key: string]: unknown;
  className?: string;
  children: ReactNode;
}

export function Layout(props: LayoutProps) {
  const { className, children } = props;
  const { is3XL, isWidthLoading } = useSize();
  const { pathname } = useLocation();
  const { setUserInfo } = useContext(UserContext);

  useEffect(() => {
    const event = accountService.onUserDetailChange(({ data }) => {
      setUserInfo(data);
    });
    return () => {
      event.dispose();
    };
  }, [setUserInfo]);

  useMount(() => {
    loadingUtils.hideLoading();
  });

  // 防止页面闪烁
  if (isWidthLoading) return <></>;
  // auth callback page，不需要layout
  if (pathname === callbackPath) return children;
  return (
    <div
      // 针对大屏做zoom
      style={{
        zoom: is3XL ? 1.25 : 1
      }}
      className={cn(
        className,
        // 100 / 1.25 = 80
        'flex h-screen flex-col overflow-y-hidden 3xl:h-[80vh]'
      )}
    >
      <Header />
      <div className="flex flex-1 flex-row">
        <SideBar />
        <main className="mr-2 h-[calc(100vh_-_61px)] w-full  overflow-y-auto bg-bgSecondaryColor 3xl:h-[calc(80vh_-_61px)]">
          {children}
        </main>
      </div>
      <LoginDialog />
      <EditProjectInfoDialog />
      <SaveAsTemplate />
    </div>
  );
}
