export default {
  // common
  common_or: '或',

  project_create_new_projects: '创建项目',
  sign_in: '登录',

  log_out: '退出',

  marketplace: '插件市场',
  studio: '编译器',
  templates: '模板',
  projects: '项目',
  extensions: '插件',
  search_here: '搜索',
  running: '运行中',

  manage: '管理',
  select_all: '全选',
  delete: '删除',
  delete_project: '您确定要删除选中的项目吗 ?',
  delete_the_projects: '您确定要删除选中的项目吗 ?',
  delete_templates: '个模板',
  delete_one_project: '您确定要删除该项目吗',
  delete_one_template: '您确定要删除该模板吗',
  delete_cancel: '取消',
  delete_confirm: '确认',

  support: '支持',
  chatbot_title: '聊天机器人',
  support_chatbot_desc: '我们计划在未来集成聊天机器人功能。 请继续关注更新！',
  doc_title: '学习文档',
  support_doc_desc: '我们提供中英文教程供您学习如何使用ChainIDE。',
  forum_title: '论坛',
  support_forum_desc:
    'ChainIDE论坛是一个讨论区块链信息的地方。 欢迎加入我们并参与讨论！',
  feedback_title: '反馈',
  support_feedback_desc:
    '我们相信ChainIDE的核心是用户，我们总是很高兴听到社区的反馈。 如果您收到任何反馈，请随时联系我们。',
  update_title: '更新记录',
  support_update_desc: `V 2.1.7 (01-16-2023) 该版本主要变化是增加了Solidity智能合约验证器插件“chainide - verifier”以及Solidity文件的跳转功能，用于跳转和查看导入的源代码。`,
  connect_git_import_repos: '连接 github 导入 repos',
  manage_imported_repos: '管理导入的 github 存储库',
  connect_git_import_gists: '连接 github 导入 gists',

  // dailog login
  sign_in_to_chainide: '登录到ChainIDE',
  get_a_free_account: '获取免费账户，无需身份认证',
  sign_in_github: 'Github授权登录',
  continue_as_guest: '访客登录',
  // dailog editproject
  edit: '编辑',

  project_name: '项目名称',
  description: '描述',
  cancel: '取消',
  confirm: '确认',
  dialog_edit_version: '版本号',
  // dialog save as template
  Save_as_template: '另存为模板',
  save_as_template: '另存为模板',
  template_name: '模板名称',
  template_desc: '模板描述',
  template_type: '模板类型',

  // create a project page
  project_create_project: '创建项目',
  project: '项目',
  welcome_create_project: `欢迎使用“创建项目”功能，这是一个旨在优化您的体验的集成工具。 请选择使用模板或导入项目。`,
  choose_a_approach: '选择一种途径',
  blockchain: '区块链',
  start_button_text: '开始',
  choose_button_text: '选择',
  start_with_basic_configuration: '使用基本模板',
  choose_public_template: '使用公共模板',
  choose_private_template: '使用私有模板',
  file: '文件',
  use_basic_template: '使用基础模板',
  start_with_basic_configuration_des: '选择基础配置，轻松创建项目。',
  use_public_template: '使用公共模板',
  choose_public_template_des: '选择公共模板创建项目。',
  use_private_template: '使用私有模板',
  choose_private_template_des: '从当前链的私有模板中选择一个进行项目创建。',
  or_import: '或选择以下方式导入',
  import_from_github: '从 Github 导入',
  import_from_gist: '从 Gist 导入',
  import_from_file: '从 文件 导入',
  private_template: '私有模板',
  save_private_template_tips:
    '该区块链还没有创建私有模板。 请先将您的项目另存为私有模板。',
  template: '模板',
  use_template: '使用模板',
  github_repo: 'GitHub 仓库',
  selected_branch: '选定分支',
  branch: '分支名称',
  gist_repo: 'Gist 仓库',
  upload_file: '上传文件',
  darg_file: '将文件拖放到此处或单击按钮上传文件',
  max: '最大',
  reupload: '重新上传',
  use_a_template: '选择模板',
  set_as_default: '设为默认',
  sandbox_pending: '加载中...',
  sandbox_start_up: '启动沙箱',
  sandbox_shut_down: '关闭沙箱',
  modify_template: '修改模板',
  open_project: '打开项目',
  no_search_result: '没有搜索结果',

  // plugins
  install: '安装',
  uninstall: '卸载',
  search_plugin: '搜索插件',
  hot_search: '热门搜索',
  chainide_plugin: 'ChainIDE 插件',
  sort_by: '排序方式',
  sort_default: '默认',
  sort_rating: '评分',
  sort_installs: '安装量',
  sort_published: '发布时间',
  sort_update: '更新时间',
  sort_uses: '使用量',

  // template
  chainide_template: 'ChainIDE 模板',
  search_template: '搜索模板',
  create: '创建',
  no_desc: 'No desc',
  free: 'Free',
  template_default: '默认',
  template_no_data: `<h2>用途和目标：</h2>
  <p>这个空白模板作为各种项目的起点。其灵活性允许根据不同的用例和项目场景进行定制。</p>
  <h2>主要特点:</h2>
  <ul>
  <li>支持各种技术栈和框架的多用途模板。</li>
  <li>干净简洁的结构，便于适应。</li>
  <li>面向不同规模项目的可扩展架构。</li>
  </ul>`,
  get_default_template_error: '获取默认模板失败',
  create_project_error: '创建项目失败',

  // plugins detail
  overview: '概述',
  to_be_released: '待发布',

  // message
  edit_message_success: '编辑成功！',
  sign_in_message_success: '登录成功！',
  refresh_token_expired_message: '登录已过期请重新登录！',
  log_out_successful: '退出登录成功！',
  save_as_template_success: '保存为模板成功！',
  delete_message_success: '删除成功！'
};
