import cn from 'classnames';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import lodash from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import CheckedSVG from '@/assets/images/project/checked.inline.svg';
import ProjectPreview from '@/assets/images/project/project_preview.inline.svg';
import UnCheckedSVG from '@/assets/images/project/unchecked.inline.svg';
import { ProgressBar } from '@/components/common/ProgressBar';
import { useTranslation } from '@/i18n';
import { UserContext } from '@/libs/userClient/userProvider';
import sandboxService from '@/services/sandboxService';
import { ITemplate } from '@/services/templateService/ITemplateService';

import { ActionDot, EActionDotType } from './ActionDot';
import { CardLayout } from './CardLayout';
import { ChainIcon } from './ChainIcon';

interface PrivateTemplateCardProps {
  className?: string;
  template: ITemplate;
  isInManage: boolean;
}

dayjs.extend(relativeTime);

export function PrivateTemplateCard(props: PrivateTemplateCardProps) {
  const { className, template, isInManage } = props;
  const { i18n } = useTranslation();
  const [isShowButton, setIsShowButton] = useState<boolean>(false);
  const { userInfo, selectTemplates, setSelectTemplates } =
    useContext(UserContext);

  const [isChecked, setIsChecked] = useState(
    lodash.includes(selectTemplates, template)
  );

  const templates = [...selectTemplates];
  const toggleTemplateCheckedState = () => {
    if (!isChecked) {
      templates.push(template);
    } else {
      templates.splice(
        templates.findIndex((item) => item.id === template.id),
        1
      );
    }
    setIsChecked(lodash.includes(templates, template));
    setSelectTemplates(templates);
  };

  const openTemplate = useCallback(async () => {
    await sandboxService.startupSandbox(template.id);

    location.href = `${location.origin}/studio/?id=${template.id}&chain=${template.chain}`;
  }, [template.chain, template.id]);

  useEffect(() => {
    setIsChecked(lodash.includes(selectTemplates, template));
  }, [selectTemplates, template]);

  return (
    <div
      className={cn(className)}
      onMouseMove={() => setIsShowButton(true)}
      onMouseLeave={() => setIsShowButton(false)}
    >
      <CardLayout
        key={template.id}
        className={cn('relative border-[rgba(255,255,255,0.01)] bg-[#20242B]')}
        onClick={() => {
          if (isInManage) {
            toggleTemplateCheckedState();
          }
        }}
      >
        {isInManage && (
          <div
            className={cn(
              'absolute left-0 top-0 z-[10] h-full w-full rounded-[4px] border-[1px] bg-bgTertiaryColor  opacity-60',
              lodash.includes(selectTemplates, template)
                ? 'border-themeColor'
                : 'border-[#2C3243]'
            )}
          />
        )}
        <div className="flex h-[165px] w-[100%] items-center justify-center rounded-t-[8px] bg-[#1C1C1E]">
          <ProjectPreview className="h-[33px] w-[115px]" />
        </div>

        <div
          className="absolute left-0 top-0 h-full w-full "
          onClick={openTemplate}
        >
          <div className="item-center flex justify-between">
            <div
              className={cn(
                'inline-block rounded-tl-[6px] rounded-br-[6px] bg-[#608B4E] px-[10px] py-1 text-[12px] uppercase text-[#FFFFFF]'
              )}
            >
              {i18n.t('template')}
            </div>
            <p className="px-4 py-1 text-xs leading-[1.5] text-[#6F7080]">
              {dayjs(Number(template.publishedDate * 1000))
                .locale(i18n.language === 'en-US' ? 'en-us' : 'zh-cn')
                .fromNow()}
            </p>
          </div>
          {isShowButton && (
            <div className="absolute left-0 bottom-[50px] flex w-full gap-[2px]">
              <ProgressBar
                className="flex w-1/3 flex-col items-center"
                label="CPU"
                process={0}
                totalProcess={320}
                processColor="#22FFD8"
                totalProcessColor="#22FFD826"
              />
              <ProgressBar
                className="flex w-1/3 flex-col items-center"
                label="RAM"
                process={0}
                totalProcess={8261}
                processColor="#28AEF3"
                totalProcessColor="#28AEF326"
              />
              <ProgressBar
                className="flex w-1/3 flex-col items-center"
                label="STORAGE"
                process={0}
                totalProcess={8261}
                processColor="#F2C8ED"
                totalProcessColor="#F2C8ED26"
              />
            </div>
          )}

          {/* <div className="mt-3 px-4 font-Poppins text-[#D8DEE4] line-clamp-3">
              {template.description}
            </div> */}
        </div>

        <div className="px-4  py-3">
          <div className="flex items-center justify-between ">
            <div className="flex items-center gap-x-1">
              <div className="relative flex h-6 w-6 items-center justify-center rounded-[2px] bg-[#33364A]">
                <ChainIcon
                  chain={template.chain}
                  className="h-[18px] w-[18px]"
                />
                {template.trending && (
                  <div className="absolute right-0 bottom-0 flex h-2 w-2 items-center justify-center rounded-full bg-bgPrimaryColor">
                    <div className="h-[6px] w-[6px] animate-pulse rounded-full bg-[#22FFD8]"></div>
                  </div>
                )}
              </div>
              <span className="mt-[2px] text-[14px] font-[400] leading-[1]">
                {template.name}
              </span>
            </div>

            {!isInManage && userInfo.userId && (
              <ActionDot
                type={EActionDotType.PrivateTemplate}
                template={template}
              />
            )}
          </div>

          {isInManage && (
            <div className="absolute right-[10px] top-[10px] z-[20]">
              {isChecked ? <CheckedSVG /> : <UnCheckedSVG />}
            </div>
          )}
        </div>
      </CardLayout>
    </div>
  );
}
