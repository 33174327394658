import React, { useCallback, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { useMount, useSearchParam } from 'react-use';

import GithubIcon from '@/assets/images/common/github_icon.inline.svg';
import { useTranslation } from '@/i18n';
import { DialogContext } from '@/libs/dialogClient/dialogProvider';
import { UserContext } from '@/libs/userClient/userProvider';
import {
  callbackPath,
  EAuthType,
  goToAuthCallbackCenter
} from '@/pages/authCallbackCenter';
import { accountService } from '@/services/api/accountService';
import { EStorageKeys, storageUtil } from '@/utils/storage';
import { IUserInfo } from '@/utils/tokenUtils';
import { updateToken } from '@/utils/updateToken';
import { UrlSearchUtils } from '@/utils/urlSearchUtils';

import { Button, EButtonType } from '../common/Button';
import { Dialog, DialogContent, DialogFooter } from '../common/Dialog';

export const LOGIN_URL_AUTH_CODE_PARMA_KEY = 'code';
export const LOGIN_URL_AUTH_REDIRECT_URI_PARAM_KEY = 'auth_redirect_uri';
export const LOGIN_URL_AUTH_TYPE_KEY = 'auth_type';
export const LOGIN_URL_AUTH_ERROR = 'error';

export function LoginDialog() {
  const { t } = useTranslation();
  const { isOpenLoginDialog, setIsOpenLoginDialog } = useContext(DialogContext);
  const [loading, setLoading] = useState<boolean>(false);
  const { setUserInfo } = useContext(UserContext);

  const handleClickLogin = useCallback(() => {
    setLoading(true);
    goToAuthCallbackCenter(EAuthType.OAUTH);
  }, []);
  const code = useSearchParam(LOGIN_URL_AUTH_CODE_PARMA_KEY);

  useMount(() => {
    // login modal是全局的组件，即使在auth callback center page，这个modal也会加载，这里要避免它消费code，应该先让auth callback center page做出来，然后回调到当前页面消费
    // auth callback 页面不应该消费code，code会传给初始页面消费
    if (location.pathname === callbackPath) return;
    if (code) {
      setLoading(true);
      accountService
        .githubOAuthCodeLogin(code)
        .then(async (data) => {
          updateToken(data);
          const userInfo = (await accountService.getUserInfo()) as IUserInfo;
          storageUtil.set(EStorageKeys.USER_INFO, userInfo);
          setUserInfo(userInfo);
          toast.success(t('sign_in_message_success'));
        })
        .catch((e: Error) => {
          toast.error(e.message);
        })
        .finally(() => {
          removeAuthParamsFromUrl();
          setIsOpenLoginDialog(false);
          setLoading(false);
        });
    }
  });

  return (
    <Dialog open={isOpenLoginDialog} onOpenChange={setIsOpenLoginDialog}>
      <DialogContent className="max-w-[336px] sm:!p-6">
        <div className="mt-5 flex flex-col items-center justify-center">
          <div className="mb-7 text-center text-[20px] font-[500] leading-[1.35]">
            {t('sign_in_to_chainide')}
          </div>
          <div className="text-[14px] text-[#B1B2B5]">
            {t('get_a_free_account')}
          </div>
          {/* <StaticImage
            src="../../assets/images/common/login_image.inline.png"
            alt="garbage"
            width={222}
            height={170}
            class="select-none"
            quality={100}
          /> */}
        </div>
        <DialogFooter className="mt-7 !flex-col gap-y-0 !space-x-0">
          <Button
            type={EButtonType.PRIMARY}
            buttonType="submit"
            loading={loading}
            className="w-full gap-x-2 py-3 text-[14px]"
            onClick={handleClickLogin}
          >
            <GithubIcon />
            {t('sign_in_github')}
          </Button>
          <div
            className="mt-4 w-full cursor-pointer text-center underline hover:opacity-75"
            onClick={() => setIsOpenLoginDialog(false)}
          >
            {t('continue_as_guest')}
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

function removeAuthParamsFromUrl() {
  const searchUtils = new UrlSearchUtils();
  searchUtils.deleteBatch([
    LOGIN_URL_AUTH_CODE_PARMA_KEY,
    LOGIN_URL_AUTH_TYPE_KEY
  ]);
  history.pushState('', '', searchUtils.url);
}
