import cn from 'classnames';
import React, { useRef, useState } from 'react';
import { useClickAway } from 'react-use';

import { ChainNameMap, EChainType } from '@/utils/const';

import { ChainIcon } from '../projects/ChainIcon';

const chainsOptions = [
  EChainType.Ethereum,
  // EChainType.BnbChain,
  // EChainType.Polygon,
  // EChainType.Nervos,
  // EChainType.Flow,
  // EChainType.Conflux,
  // EChainType.Dfinity,
  // EChainType.Aptos,
  // EChainType.Chain33,
  // EChainType.AntChain,
  // EChainType.FiscoBcos,
  // EChainType.AstarEvm,
  // EChainType.AstarWasm,
  EChainType.Sui
  // EChainType.Xdc,
  // EChainType.Iris
];

interface ICreateChainSelectorProps {
  chain: EChainType;
  setChain: (chain: EChainType) => void;
}

export function CreateChainSelector(props: ICreateChainSelectorProps) {
  const { chain, setChain } = props;
  const ref = useRef<HTMLDivElement | null>(null);
  const [show, setShow] = useState(false);
  useClickAway(ref, () => {
    setShow(false);
  });
  return (
    <div className="relative select-none" ref={ref}>
      <div
        onClick={() => {
          setShow(!show);
        }}
        className="mt-[10px] mb-5 box-border flex h-12 w-full shrink-0 cursor-pointer items-center justify-between rounded-common bg-[#20242B] pl-4 pr-[10px]"
      >
        <div className="flex items-center gap-x-1">
          <ChainIcon chain={chain} className="h-[20px]" />
          <span className="mt-[2px]">{ChainNameMap[chain]}</span>
        </div>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.9999 7.99545C16.0004 8.12349 15.9721 8.25 15.9172 8.36568C15.8624 8.48137 15.7823 8.58329 15.6828 8.66395L10.5405 12.8036C10.3871 12.9296 10.1947 12.9985 9.99622 12.9985C9.7977 12.9985 9.60534 12.9296 9.45199 12.8036L4.30962 8.51825C4.13459 8.37278 4.02452 8.16373 4.00363 7.9371C3.98273 7.71047 4.05272 7.48482 4.1982 7.3098C4.34367 7.13477 4.55272 7.0247 4.77935 7.00381C5.00598 6.98291 5.23163 7.0529 5.40665 7.19838L10.0005 11.0294L14.5944 7.32694C14.7202 7.22213 14.8734 7.15556 15.0358 7.13509C15.1983 7.11463 15.3632 7.14113 15.5111 7.21145C15.6589 7.28178 15.7835 7.39299 15.8702 7.53193C15.9568 7.67087 16.0019 7.83172 15.9999 7.99545Z"
            fill="white"
            className={cn('origin-center duration-300', show && 'rotate-180')}
          />
        </svg>
      </div>
      <ul
        className={cn(
          'absolute top-12 mt-1 max-h-[300px] w-full flex-col gap-y-1 overflow-y-auto rounded-common bg-[#2D343E] text-sm duration-300',
          show
            ? 'z-10 flex opacity-100 shadow-common'
            : '-z-10 hidden opacity-0'
        )}
      >
        {chainsOptions.map((item) => {
          const isAll = item === EChainType.All;
          return (
            <li
              key={item}
              onClick={() => {
                setChain(item);
                setShow(false);
              }}
              className="flex h-10 shrink-0 cursor-pointer items-center gap-x-[6px] overflow-y-auto pl-4 text-[13px] hover:bg-[#3D4755]"
            >
              <div className="flex h-5 w-5 items-center justify-center overflow-hidden">
                {isAll ? (
                  ChainNameMap[EChainType.All]
                ) : (
                  <ChainIcon chain={item} />
                )}
              </div>
              <span>{isAll ? '' : ChainNameMap[item]}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
