import cn from 'classnames';
import React, { ReactNode } from 'react';

import Loading from '@/assets/images/plugin/loading.inline.svg';

import { EmptyProjectCard } from '../pages/projects/EmptyProjectCard';

interface LoaderProps {
  className?: string;
  isLoading: boolean;
  children?: React.ReactNode;
  loadingClassName?: string;
  loadingPlaceholder?: React.ReactNode;
  style?: React.CSSProperties;
  extraComponent?: ReactNode;
}

export const Loader: React.FC<LoaderProps> = (props) => {
  const {
    className,
    isLoading,
    children,
    loadingClassName,
    loadingPlaceholder,
    extraComponent,
    style = {}
  } = props;

  return (
    <div
      className={cn(
        isLoading && `h-10 ${loadingClassName || ''}`,
        isLoading && !loadingPlaceholder && 'relative',
        className
      )}
      style={style}
    >
      {isLoading && extraComponent}
      {isLoading
        ? loadingPlaceholder || (
            <div className="absolute left-[50%] top-[50%] -translate-x-[50%] -translate-y-[50%]">
              <Loading className="animate-spin" width={24} />
            </div>
          )
        : children}
    </div>
  );
};
