import 'react-toastify/dist/ReactToastify.css';
import '@/styles/global.css';
import 'react-loading-skeleton/dist/skeleton.css';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import amplitude from 'amplitude-js';
import { REACT_APP_AMPLITUDE_API_KEY } from 'gatsby-env-variables';
import React from 'react';
// import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';

import { I18nextProvider } from '@/i18n';
import { DialogProvider } from '@/libs/dialogClient/dialogProvider';
import { UserProvider } from '@/libs/userClient/userProvider';

interface RootElementProps {
  children: JSX.Element;
}
const queryClient = new QueryClient();

export function RootElement(props: RootElementProps) {
  const { children } = props;
  amplitude.getInstance().options.apiEndpoint = 'api2.amplitude.com';
  amplitude.getInstance().init(REACT_APP_AMPLITUDE_API_KEY);

  return (
    <I18nextProvider>
      <QueryClientProvider client={queryClient}>
        <ToastContainer
          theme="dark"
          autoClose={3000}
          newestOnTop={false}
          closeOnClick={true}
          rtl={false}
          draggable={false}
          pauseOnHover={false}
        />

        <UserProvider>
          <DialogProvider>{children}</DialogProvider>
        </UserProvider>
      </QueryClientProvider>
    </I18nextProvider>
  );
}
