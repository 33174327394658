import React, { useContext, useEffect, useMemo, useState } from 'react';

import RAMUseWaringSVG from '@/assets/images/common/ram_use_warning.inline.svg';
import ResourceUseWarningSVG from '@/assets/images/common/resource_use_warning.inline.svg';
import { UserContext } from '@/libs/userClient/userProvider';
import portForwardService from '@/services/portForwards';
import { IResourceQuota } from '@/services/portForwards/IPortForwardService';

import { LanguageSwitch } from './LanguageSwitch';
import { NavList } from './NavList';
import { ProgressBar } from './ProgressBar';
import { Support } from './Support';
import { ThemeModeSwitch } from './ThemeModeSwitch';
interface IResourceUsage {
  cpu: number;
  memory: number;
  disk: number;
}
export function SideBar() {
  const { userInfo, resourceUsage, projectList } = useContext(UserContext);
  const [resourceQuota, setResourceQuota] = useState<IResourceQuota>({
    cpu: 4,
    memory: 16384,
    storage: 102400
  });

  const [totalResourceUsage, setTotalResourceUsage] = useState<IResourceUsage>({
    cpu: 0,
    memory: 0,
    disk: 0
  });

  useEffect(() => {
    setTimeout(() => {
      const cpu = resourceUsage.reduce((accumulator, currentItem) => {
        return accumulator + (parseFloat(currentItem.cpuUsage) || 0);
      }, 0);
      const memory = resourceUsage.reduce((accumulator, currentItem) => {
        return accumulator + (parseFloat(currentItem.memoryUsage) || 0);
      }, 0);
      const disk = projectList.reduce((accumulator, currentItem) => {
        return accumulator + Number(currentItem.diskUsage);
      }, 0);

      setTotalResourceUsage({ cpu, memory, disk });
    }, 1000);
  }, [projectList, resourceUsage, resourceUsage.length]);

  useEffect(() => {
    portForwardService
      .getUserResourceQuota()
      .then((data) => setResourceQuota(data))
      .catch((e) => console.log(e));
  }, [userInfo?.userId]);

  return (
    <aside className="flex h-full min-w-[186px] flex-col justify-between border-r-[1px] border-solid border-[#2b2c34] ">
      <NavList />
      <div>
        {userInfo.userId && (
          <div className="mx-4 flex flex-col gap-[10px] rounded-[6px] bg-[#20242B] py-5 px-4">
            <ProgressBar
              className="flex flex-col gap-[6px]"
              label="CPU"
              process={totalResourceUsage.cpu}
              totalProcess={resourceQuota.cpu * 1000}
              unit=" "
              processColor={
                isExceeds(totalResourceUsage.cpu, resourceQuota.cpu * 1000)
                  ? '#EF2E68'
                  : '#22FFD8'
              }
              totalProcessColor="#3E3F4D"
              extraComponent={
                isExceeds(totalResourceUsage.cpu, resourceQuota.cpu * 1000) ? (
                  <ResourceUseWarningSVG className="h-[12px]" />
                ) : undefined
              }
            />
            <ProgressBar
              className="flex flex-col gap-[6px]"
              label="RAM"
              process={Math.ceil(totalResourceUsage.memory / 1024)}
              totalProcess={resourceQuota.memory / 1024}
              unit="GB"
              processColor={
                isExceeds(totalResourceUsage.memory, resourceQuota.memory)
                  ? '#F27742'
                  : '#28AEF3'
              }
              totalProcessColor="#3E3F4D"
              extraComponent={
                isExceeds(totalResourceUsage.memory, resourceQuota.memory) ? (
                  <RAMUseWaringSVG className="w-[12px]" />
                ) : undefined
              }
            />
            <ProgressBar
              className="flex flex-col gap-[6px]"
              label="STORAGE"
              process={Math.ceil(
                Number(totalResourceUsage.disk / 1024 / 1024 / 1024)
              )}
              totalProcess={resourceQuota.storage / 1024}
              unit="Gi"
              processColor={
                isExceeds(
                  Math.ceil(
                    Number(totalResourceUsage.disk / 1024 / 1024 / 1024)
                  ),
                  resourceQuota.storage / 1024
                )
                  ? '#EF2E68'
                  : '#F2C8ED'
              }
              totalProcessColor="#3E3F4D"
              extraComponent={
                isExceeds(
                  Math.ceil(
                    Number(totalResourceUsage.disk / 1024 / 1024 / 1024)
                  ),
                  resourceQuota.storage / 1024
                ) ? (
                  <ResourceUseWarningSVG className="h-[12px]" />
                ) : undefined
              }
            />
          </div>
        )}
        <div className="flex select-none flex-col gap-y-2 py-5 px-4">
          <LanguageSwitch />
          {/* <ThemeModeSwitch /> */}
          <Support />
        </div>
      </div>
    </aside>
  );
}

function isExceeds(process: number, totalProcess: number) {
  return process / totalProcess >= 0.8;
}
