import { useInfiniteQuery } from '@tanstack/react-query';
import React, {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { v4 as uuid } from 'uuid';

import ScrollList from '@/components/common/ScrollList';
import { Skeleton } from '@/components/common/Skeleton';
import { useTranslation } from '@/i18n';
import { UserContext } from '@/libs/userClient/userProvider';
import { projectService } from '@/services/projectService';
import { IProject } from '@/services/projectService/IProjectService';
import templateService from '@/services/templateService';
import { Locale } from '@/services/templateService/ITemplateService';
import { SearchParams, TagSearchParams } from '@/utils/const';
import { useGetDeviceId } from '@/utils/hooks/useGetDeviceId';
import { useRouterChange } from '@/utils/hooks/useRouterChange';

import { CardLayout } from './CardLayout';
import { EmptyProjectCard } from './EmptyProjectCard';
import { PrivateTemplateCard } from './PrivateTemplateCard';
import { ProjectCard } from './ProjectCard';
interface IProjectListProps {
  isInMange: boolean;
  isRunning: boolean;
}
interface PageParam {
  page: number;
}

export enum IProjectType {
  Project = 'Project',
  Template = 'Template'
}

export function ProjectList(props: IProjectListProps) {
  const { isInMange, isRunning } = props;

  const { deviceId } = useGetDeviceId();
  const { i18n } = useTranslation();
  const { userInfo } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    setProjectList,
    privateTemplateList,
    setPrivateTemplateList,
    reloadProjectListNumber
  } = useContext(UserContext);

  const [query, setQuery] = useState('');
  const [chain, setChain] = useState('');

  useRouterChange(() => {
    const search = new URLSearchParams(window.location.search);
    setChain(search.get(TagSearchParams.CHAIN) || '');
    setQuery(search.get(SearchParams.QUERY) || '');
  });

  const {
    data,
    isLoading: isProjectLoading,
    fetchNextPage,
    hasNextPage
  } = useInfiniteQuery(
    [
      'projectSearch',
      chain,
      query,
      isRunning,
      userInfo.userId,
      reloadProjectListNumber
    ],
    ({ pageParam = { page: 1 } }) => {
      return projectService.queryWorkspacePage({
        deviceId,
        limit: 4,
        running: isRunning || undefined,
        page: (pageParam as PageParam).page,
        chain: ['all', ''].includes(chain) ? undefined : chain,
        searchText: query === '' ? undefined : query
      });
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.hasNextPage) {
          return { page: lastPage.page };
        }
        return undefined;
      },
      retry: false
    }
  );

  useEffect(() => {
    if (data) {
      const list: IProject[] = [];
      data.pages.forEach((item) => list.push(...item.workspaceInfoList));
      setProjectList(list);
    }
  }, [data, setProjectList]);

  useEffect(() => {
    if (!userInfo.userId) return;
    setIsLoading(true);
    templateService
      .getTemplates(i18n.language === 'en-US' ? Locale.EN : Locale.ZH, false)
      .then((data) => setPrivateTemplateList(data))
      .finally(() => setIsLoading(false));
  }, [
    deviceId,
    i18n,
    setPrivateTemplateList,
    setProjectList,
    userInfo.userId,
    reloadProjectListNumber
  ]);

  const filterPrivateTemplateList = useMemo(() => {
    let list = privateTemplateList;

    if (chain && chain !== 'all') {
      list = list.filter((item) => item.chain === chain);
    }

    if (query) {
      list = list.filter((item) =>
        item.name.toLowerCase().includes(query.toLowerCase())
      );
    }
    if (isRunning) {
      list = [];
    }

    return list;
  }, [chain, isRunning, privateTemplateList, query]);

  return (
    <div className="">
      <ScrollList
        className="mb-4 grid grid-cols-1 gap-[22px] sm:grid-cols-[repeat(auto-fill,minmax(270px,1fr))]"
        isLoading={isLoading || isProjectLoading}
        loadMore={() => fetchNextPage()}
        hasMore={isLoading || hasNextPage}
        threshold={1000}
        loader={generateProjectPlaceHolder(4)}
        isEmpty={!data?.pages?.[0]?.workspaceInfoList?.length}
        emptyIcon={true}
        emptyTitle={i18n.t('no_search_result') || ''}
        extraComponent={<EmptyProjectCard />}
      >
        <EmptyProjectCard />
        {data?.pages.map((item, index) => (
          <Fragment key={`${index}-${uuid()}`}>
            {item.workspaceInfoList.map((project) => (
              <ProjectCard
                isInManage={isInMange}
                project={project}
                key={project.workspaceId}
              />
            ))}
          </Fragment>
        ))}
        {!isLoading &&
          filterPrivateTemplateList.map((template) => (
            <PrivateTemplateCard
              isInManage={isInMange}
              template={template}
              key={template.id}
            />
          ))}
      </ScrollList>
    </div>
  );
}

export function generateProjectPlaceHolder(length: number) {
  const result: number[] = [];
  for (let i = 0; i < length; i++) {
    result.push(i);
  }
  return (
    <>
      {result.map((item) => (
        <CardLayout
          key={item}
          className="relative min-h-[215px] !justify-start border-[#2C3243] bg-[#21222D]"
        >
          <Skeleton className="h-[135px] !w-full" />
          <div className="px-4  py-3">
            <Skeleton
              count={1}
              containerClassName="my-1"
              className="h-4 !w-full"
            />
          </div>
          <div className="absolute left-0 bottom-0 w-full ">
            <Skeleton
              count={1}
              containerClassName="my-1"
              className="h-8 !w-full !rounded-b-[8px]"
            />
          </div>
        </CardLayout>
      ))}
    </>
  );
}
