import cn from 'classnames';
import React, { ReactNode } from 'react';

interface IProgressBarProps {
  className?: string;
  label: string;
  process: number;
  totalProcess: number;
  unit?: string;
  totalProcessColor: string;
  processColor: string;
  extraComponent?: ReactNode;
}
export function ProgressBar(props: IProgressBarProps) {
  const {
    className,
    label,
    process,
    totalProcess,
    unit,
    totalProcessColor,
    processColor,
    extraComponent
  } = props;

  return (
    <div className={cn(className, 'text-[12px]')}>
      <div className="flex justify-between">
        <div className="flex items-center gap-1">
          <label>{label}</label>
          {extraComponent}
        </div>
        {unit && totalProcess.toString() && process.toString() && (
          <div>
            <span>{process}</span>
            <span className="text-[#939ABB]">{`/${totalProcess} ${unit}`}</span>
          </div>
        )}
      </div>
      <div
        className="h-1 w-[100%] rounded-[1px]"
        style={{ background: totalProcessColor }}
      >
        <div
          className="h-full rounded-[1px]"
          style={{
            width: `${(process / totalProcess) * 100}%`,
            maxWidth: '100%',
            background: processColor
          }}
        />
      </div>
    </div>
  );
}
