import { useContext, useMemo } from 'react';

import { UserContext } from '@/libs/userClient/userProvider';
export function useRole() {
  const { userInfo } = useContext(UserContext);
  const isGlobalAdmin = useMemo(
    () => userInfo.authorities.includes('admin'),
    [userInfo.authorities]
  );
  const isConfluxAdmin = useMemo(
    () => userInfo.authorities.includes('conflux'),
    [userInfo.authorities]
  );
  return {
    authorities: userInfo.authorities,
    name: userInfo.username,
    isGlobalAdmin,
    isConfluxAdmin
  };
}
