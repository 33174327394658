import cn from 'classnames';
import lodash from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import CloseSVG from '@/assets/images/common/close.inline.svg';
import DeleteSVG from '@/assets/images/common/delete.inline.svg';
import ManageSVG from '@/assets/images/common/manage.inline.svg';
import { Button, EButtonType } from '@/components/common/Button';
import { Checker } from '@/components/common/Checker';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter
} from '@/components/common/Dialog';
import { useTranslation } from '@/i18n';
import { DialogContext } from '@/libs/dialogClient/dialogProvider';
import { UserContext } from '@/libs/userClient/userProvider';
import { projectService } from '@/services/projectService';
import templateService from '@/services/templateService';

interface IManageEntryProps {
  isInMange: boolean;
  setInManage: (isInManage: boolean) => void;
  isSelectAll: boolean;
  setSelectAll: (isSelectAll: boolean) => void;
}
export function ManageEntry(props: IManageEntryProps) {
  const { isInMange, setInManage, isSelectAll, setSelectAll } = props;
  const { t } = useTranslation();
  const [isDeleteing, setDeleteing] = useState(false);
  const { isDeleteProjects, setIsDeleteProjects } = useContext(DialogContext);
  const {
    selectProjects,
    selectTemplates,
    privateTemplateList,
    projectList,
    setSelectProjects,
    setSelectTemplates,
    reloadProjectListNumber,
    setReloadProjectListNumber
  } = useContext(UserContext);

  const handelSelectAll = useCallback(() => {
    if (projectList.length + privateTemplateList.length <= 0) return;

    if (isSelectAll) {
      setSelectTemplates([]);
      setSelectProjects([]);
    } else {
      setSelectTemplates(privateTemplateList);
      setSelectProjects(projectList);
    }
  }, [
    isSelectAll,
    privateTemplateList,
    projectList,
    setSelectProjects,
    setSelectTemplates
  ]);

  const removeProjects = useCallback(async () => {
    const selectedNumber = selectProjects.length + selectTemplates.length;
    if (selectedNumber <= 0) return;
    try {
      setDeleteing(true);
      if (selectedNumber === 1) {
        if (selectProjects.length === 1) {
          await projectService.removeProject(selectProjects[0].workspaceId);
        } else {
          await templateService.removeTemplate(selectTemplates[0].id);
        }
      } else {
        if (selectProjects.length >= 1) {
          const selectProjectIds: string[] = [];
          selectProjects.forEach((item) =>
            selectProjectIds.push(item.workspaceId)
          );
          await projectService.removeProjects(selectProjectIds);
        }

        if (selectTemplates.length >= 1) {
          const selectTemplateIds: string[] = [];
          selectTemplates.forEach((item) => selectTemplateIds.push(item.id));
          await templateService.removeTemplates(selectTemplateIds);
        }
      }
      setReloadProjectListNumber(reloadProjectListNumber + 1);
      setInManage(false);
      toast.success(t('delete_message_success'));
    } catch (e) {
      toast.error((e as Error).message);
    } finally {
      setDeleteing(false);
      setSelectProjects([]);
      setSelectTemplates([]);
      setIsDeleteProjects(false);
    }
  }, [
    reloadProjectListNumber,
    selectProjects,
    selectTemplates,
    setInManage,
    setIsDeleteProjects,
    setReloadProjectListNumber,
    setSelectProjects,
    setSelectTemplates,
    t
  ]);

  useEffect(() => {
    const isProjectSelectAll = lodash.isEqual(
      lodash.sortBy(selectProjects, 'workspaceId'),
      lodash.sortBy(projectList, 'workspaceId')
    );
    const isTemplateSelectAll = lodash.isEqual(
      lodash.sortBy(selectTemplates, 'id'),
      lodash.sortBy(privateTemplateList, 'id')
    );

    setSelectAll(isProjectSelectAll && isTemplateSelectAll);
  }, [
    privateTemplateList,
    projectList,
    selectProjects,
    selectTemplates,
    setSelectAll
  ]);

  return (
    <div className="relative">
      <div
        className={cn(
          'absolute -top-1 left-0 flex h-[26px] items-center gap-x-[19px] overflow-hidden duration-300 sm:left-0 sm:right-auto',
          isInMange ? 'z-[20] w-[246px] opacity-100' : '-z-[20] w-0 opacity-0'
        )}
      >
        <Dialog
          open={isDeleteProjects}
          onOpenChange={() => {
            if (!isInMange) {
              setSelectTemplates([]);
              setSelectProjects([]);
            }
            setIsDeleteProjects(false);
          }}
        >
          <CloseSVG
            className="flex-none cursor-pointer text-bgDropdownHoverColor duration-300 hover:opacity-75"
            onClick={() => {
              setInManage(!isInMange);
              setSelectTemplates([]);
              setSelectProjects([]);
            }}
          />
          <Checker
            isChecked={isSelectAll}
            handelClick={handelSelectAll}
            label={`${t('select_all')}`}
          />

          <Button
            type={EButtonType.PRIMARY}
            className="h-[26px] w-[89px] border-0 text-textPrimaryColor outline-none"
            onClick={() => {
              if (selectProjects.length + selectTemplates.length >= 1) {
                setIsDeleteProjects(true);
              }
            }}
          >
            <div className="flex items-center gap-x-[2px]">
              <DeleteSVG />
              <span>{t('delete')}</span>
            </div>
          </Button>

          <DialogContent className="max-w-[400px] sm:!p-6">
            <div className="flex flex-col items-center justify-center">
              <div className="mb-5 text-center text-[20px] font-medium leading-[1.35]">
                {t('delete')}
              </div>
              {isInMange && (
                <span className="text-center text-[16px] font-medium leading-[1.35]">
                  {selectProjects.length + selectTemplates.length === 1
                    ? t('delete_project')
                    : t('delete_the_projects')}
                </span>
              )}

              {!isInMange && (
                <span className="text-center text-[16px] font-medium leading-[1.35]">
                  {t('delete_one_project')}?
                </span>
              )}
            </div>
            <DialogFooter className="mt-7">
              <DialogClose asChild={true}>
                <Button
                  type={EButtonType.GHOST}
                  className="h-10 w-full font-medium "
                >
                  {t('delete_cancel')}
                </Button>
              </DialogClose>
              <Button
                loading={isDeleteing}
                type={EButtonType.PRIMARY}
                onClick={removeProjects}
                buttonType="submit"
                className="h-10 w-full font-medium "
              >
                {t('delete_confirm')}
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
      <div
        onClick={() => {
          setInManage(!isInMange);
        }}
        className={cn(
          'flex cursor-pointer flex-nowrap items-center  gap-x-2 overflow-hidden text-themeColor duration-300 ease-linear hover:text-themeColorHeavy',
          isInMange ? '-z-10 opacity-0' : 'z-10 opacity-100'
        )}
      >
        <ManageSVG className="mb-[1px]" />
        <span className="w-[70px]">{t('manage')}</span>
      </div>
    </div>
  );
}
