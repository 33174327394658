import React, { useCallback, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import semver from 'semver';

import StarIcon from '@/assets/images/project/star.inline.svg';
import { useTranslation } from '@/i18n';
import { DialogContext } from '@/libs/dialogClient/dialogProvider';
import { UserContext } from '@/libs/userClient/userProvider';
import { projectService } from '@/services/projectService';
import templateService from '@/services/templateService';
import { Locale } from '@/services/templateService/ITemplateService';

import { Button, EButtonType } from '../common/Button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter
} from '../common/Dialog';

export function EditProjectInfoDialog() {
  const { isOpenEditDialog, setIsOpenEditDialog } = useContext(DialogContext);
  const {
    selectProjects,
    selectTemplates,
    setSelectProjects,
    setSelectTemplates,
    reloadProjectListNumber,
    setReloadProjectListNumber
  } = useContext(UserContext);
  const { i18n, t } = useTranslation();

  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [version, setVersion] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const [versionMessage, setVersionMessage] = useState<string>('');

  const edit = useCallback(async () => {
    if (!name || !description) return;
    if (
      selectTemplates.length > 0 &&
      selectTemplates[0].isPublic &&
      semver.gt(selectTemplates[0]?.latestVersion, version)
    ) {
      setVersionMessage('Please enter correct version !');
      return;
    }
    try {
      setLoading(true);
      if (selectProjects.length > 0) {
        await projectService.updateProjectInfo(
          selectProjects[0].workspaceId,
          name,
          description
        );
      }
      if (selectTemplates.length > 0 && !selectTemplates[0].isPublic) {
        await templateService.updatePrivateTemplateInfo({
          templateId: selectTemplates[0].id,
          name,
          description
        });
      }
      if (selectTemplates.length > 0 && selectTemplates[0].isPublic) {
        await templateService.createTemplate(
          selectTemplates[0].id,
          {
            isPublic: selectTemplates[0].isPublic,
            chain: selectTemplates[0].chain,
            name,
            description,
            displayName: name,
            version,
            icon: 'https://marketplace-beta.media.chainide.com/templates/default_icon.svg',
            readmeFile:
              'https://marketplace-beta.media.chainide.com/templates/default_readme.md',
            defaultReadmePath: selectTemplates[0].defaultReadmePath,
            defaultOrder: selectTemplates[0].defaultOrder
          },
          i18n.language === 'en-US' ? Locale.EN : Locale.ZH
        );
      }
      setReloadProjectListNumber(reloadProjectListNumber + 1);

      toast.success(t('edit_message_success'));
    } catch (e) {
      toast.error((e as Error).message);
    } finally {
      setLoading(false);
      setIsOpenEditDialog(false);
    }
  }, [
    description,
    i18n.language,
    name,
    reloadProjectListNumber,
    selectProjects,
    selectTemplates,
    setIsOpenEditDialog,
    setReloadProjectListNumber,
    t,
    version
  ]);

  useEffect(() => {
    setName(selectProjects[0]?.workspaceName ?? selectTemplates[0]?.name ?? '');
    setDescription(
      selectProjects[0]?.description ?? selectTemplates[0]?.description ?? ''
    );
    setVersion(selectTemplates[0]?.latestVersion);
  }, [selectProjects, selectTemplates]);

  return (
    <Dialog
      open={isOpenEditDialog}
      onOpenChange={() => {
        setSelectProjects([]);
        setSelectTemplates([]);
        setVersionMessage('');
        setIsOpenEditDialog(false);
      }}
    >
      <DialogContent>
        <div className="flex flex-col items-center justify-center">
          <div className="mb-6 text-center text-xl font-medium leading-[1.35]">
            {i18n.t('edit')}
          </div>

          <div className="flex w-full flex-col gap-y-2">
            <label className="flex items-start">
              <div className="mt-1"> {i18n.t('project_name')}</div> <StarIcon />
            </label>
            <input
              className="rounded-[6px] border-0 bg-[#2D343E] py-3 px-4 outline-none placeholder:text-[#6F7780]"
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
          </div>

          <div className="mt-4 flex w-full flex-col gap-y-2">
            <label className="flex items-start">
              <div className="mt-1">{i18n.t('description')}</div> <StarIcon />
            </label>
            <textarea
              className="min-h-[90px] rounded-[6px] border-0 bg-[#2D343E] py-3 px-4 outline-none"
              value={description}
              onChange={(event) => setDescription(event.target.value)}
            />
          </div>

          {selectTemplates.length > 0 && selectTemplates[0].isPublic && (
            <div className="flex w-full flex-col gap-y-2">
              <label className="flex items-start">
                <div className="mt-1"> {i18n.t('dialog_edit_version')}</div>{' '}
                <StarIcon />
              </label>
              <input
                className="rounded-[6px] border-0 bg-[#2D343E] py-3 px-4 outline-none placeholder:text-[#6F7780]"
                value={version}
                onChange={(event) => setVersion(event.target.value)}
              />
              {versionMessage && (
                <span className="-mt-[4px] text-[12px] text-[red]">
                  {versionMessage}
                </span>
              )}
            </div>
          )}
        </div>
        <DialogFooter className="mt-8">
          <DialogClose asChild={true}>
            <Button
              type={EButtonType.GHOST}
              className="h-10 w-full font-medium sm:w-[192px]"
              onClick={() => {
                setSelectProjects([]);
                setSelectTemplates([]);
              }}
            >
              {i18n.t('cancel')}
            </Button>
          </DialogClose>
          <Button
            loading={loading}
            type={EButtonType.PRIMARY}
            buttonType="submit"
            className="h-10 w-full font-medium sm:w-[192px]"
            onClick={edit}
          >
            {i18n.t('confirm')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
