export enum EChainType {
  'Ethereum' = 'ethereum',
  'BnbChain' = 'binance',
  'Polygon' = 'polygon',
  'Nervos' = 'nervos',
  'Flow' = 'flow',
  'Conflux' = 'conflux',
  'Dfinity' = 'dfinity',
  'Aptos' = 'aptos',
  'Chain33' = 'chain33',
  'AntChain' = 'ant',
  'FiscoBcos' = 'fisco',
  'AstarEvm' = 'astar-evm',
  'AstarWasm' = 'astar-wasm',
  'Sui' = 'sui',
  'Xdc' = 'xdc',
  'Iris' = 'iris',
  /**
   * 要和后端确认，选择全部链如何传值，传空还是all
   */
  'All' = 'all'
}

export const ChainNameMap = {
  [EChainType.All]: 'All',
  [EChainType.Ethereum]: 'Ethereum',
  [EChainType.BnbChain]: 'BNB Chain',
  [EChainType.Polygon]: 'Polygon',
  [EChainType.Nervos]: 'Nervos',
  [EChainType.Flow]: 'Flow',
  [EChainType.Conflux]: 'Conflux',
  [EChainType.Dfinity]: 'Internet Computer',
  [EChainType.Aptos]: 'Aptos',
  [EChainType.Chain33]: 'Chain33',
  [EChainType.AntChain]: 'Ant Chain',
  [EChainType.FiscoBcos]: 'FISCO BCOS',
  [EChainType.AstarEvm]: 'Astar EVM',
  [EChainType.AstarWasm]: 'Astar WASM',
  [EChainType.Sui]: 'Sui',
  [EChainType.Xdc]: 'XDC',
  [EChainType.Iris]: 'IRISnet'
};

export const EPagePath = {
  ProjectListPage: '/projects',
  ProjectCreatePage: '/projects/create',
  TemplateListPage: '/templates',
  PublicTemplateListPage: '/templates/public',
  PrivateTemplateListPage: '/templates/private',
  ExtensionsListPage: '/plugins',
  AuthCallbackCenterPage: '/authCallbackCenter'
};

export const websitePathPrefix = '/dashboard';

export const TagSearchParams = {
  /**
   * 付费方案，目前有free和paid两种
   */
  PRICE: 'price',
  /**
   * 链
   */
  CHAIN: 'chain',

  /**
   * 标签，只有插件搜索才需要这个参数，模板没有
   */
  CATEGORY: 'category',
  STATUS: 'status'
};

export const OtherSearchParams = {
  /**
   * 搜索关键字
   */
  QUERY: 'query',

  /**
   * 排序字段
   */
  SORT: 'sort',
  /**
   * 是否显示advanced search
   */
  SHOW_ADVANCED_SEARCH: 'showAdvancedSearch'
};

export const HomeSearchParams = {
  /**
   * 搜索关键字
   */
  QUERY: 'query',
  /**
   * 搜索类型
   */
  SEARCH_TYPE: 'search_type'
};

export const SearchParams = { ...TagSearchParams, ...OtherSearchParams };
