import { Link, navigate } from 'gatsby';
import React, { useContext } from 'react';

import PlugSVG from '@/assets/images/common/plus.inline.svg';
import { useTranslation } from '@/i18n';
import { DialogContext } from '@/libs/dialogClient/dialogProvider';
import { UserContext } from '@/libs/userClient/userProvider';
import { EPagePath } from '@/utils/const';

import { CardLayout } from './CardLayout';
export function EmptyProjectCard() {
  const { t } = useTranslation();
  const { userInfo } = useContext(UserContext);
  const { setIsOpenLoginDialog } = useContext(DialogContext);
  return (
    <div
      onClick={() => {
        if (userInfo.userId) {
          void navigate(EPagePath.ProjectCreatePage);
        } else {
          setIsOpenLoginDialog(true);
        }
      }}
    >
      <CardLayout className="h-full min-h-[215px] items-center !justify-center border-themeColorHeavy bg-transparent xl:aspect-[2.55]">
        <PlugSVG className="text-[#4C97FC]" />
        <p className="mt-4 text-[#4C97FC]">
          {t('project_create_new_projects')}
        </p>
      </CardLayout>
    </div>
  );
}
