import 'dayjs/locale/zh-cn';

import cn from 'classnames';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import lodash from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import CheckedSVG from '@/assets/images/project/checked.inline.svg';
import ProjectPreview from '@/assets/images/project/project_preview.inline.svg';
import UnCheckedSVG from '@/assets/images/project/unchecked.inline.svg';
import { ProgressBar } from '@/components/common/ProgressBar';
import { useTranslation } from '@/i18n';
import { DialogContext } from '@/libs/dialogClient/dialogProvider';
import { UserContext } from '@/libs/userClient/userProvider';
import portForwardService from '@/services/portForwards';
import { IResourceUsage } from '@/services/portForwards/IPortForwardService';
import { projectService } from '@/services/projectService';
import { IProject } from '@/services/projectService/IProjectService';
import sandboxService from '@/services/sandboxService';

import { ActionDot, EActionDotType } from './ActionDot';
import { CardLayout } from './CardLayout';
import { ChainIcon } from './ChainIcon';
interface IProjectCardProps {
  project: IProject;
  isInManage: boolean;
}
dayjs.extend(relativeTime);

export function ProjectCard(props: IProjectCardProps) {
  const { project, isInManage } = props;
  const { i18n } = useTranslation();
  const {
    selectProjects,
    setSelectProjects,
    userInfo,
    resourceUsage,
    setResourceUsage
  } = useContext(UserContext);
  const { setIsOpenLoginDialog } = useContext(DialogContext);
  const [isShowButton, setIsShowButton] = useState<boolean>(false);

  const [currentResourceUsage, setCurrentResourceUsage] =
    useState<IResourceUsage>({
      cpuTotal: project.cpuQuota ? project.cpuQuota : '250m',
      cpuUsage: '0m',
      diskTotal: project.diskQuota
        ? parseFloat(project.diskQuota) * 1024 * 1024 * 1024
        : 1020674048,
      diskUsage: Number(project.diskUsage) ?? 0,
      memoryTotal: project.cpuQuota
        ? `${parseFloat(project.cpuQuota) * 1024}Mi`
        : '1024Mi',
      memoryUsage: '0Mi'
    });

  const [isChecked, setIsChecked] = useState(
    lodash.includes(selectProjects, project)
  );

  const projects = [...selectProjects];
  const toggleProjectCheckedState = () => {
    if (!isChecked) {
      projects.push(project);
    } else {
      projects.splice(
        projects.findIndex((item) => item.workspaceId === project.workspaceId),
        1
      );
    }
    setIsChecked(lodash.includes(projects, project));
    setSelectProjects(projects);
  };

  const openProject = useCallback(async () => {
    if (!userInfo.userId) {
      setIsOpenLoginDialog(true);
      return;
    }
    await sandboxService.startupSandbox(project.workspaceId);

    await projectService.getWorkspaceInfo(project.workspaceId);

    location.href = `${location.origin}/studio/?id=${project.workspaceId}&chain=${project.fromChain}`;
  }, [
    project.fromChain,
    project.workspaceId,
    setIsOpenLoginDialog,
    userInfo.userId
  ]);

  useEffect(() => {
    setIsChecked(lodash.includes(selectProjects, project));
  }, [project, selectProjects]);

  useEffect(() => {
    if (!project.running) return;

    async function getProjectUsage() {
      const portForwardData = await portForwardService.getOpensumiPortForward(
        project.workspaceId
      );
      const usage = await portForwardService.getResourceUsage(
        `https://${portForwardData.host}`
      );

      return usage;
    }
    getProjectUsage()
      .then((data) => {
        const list = resourceUsage;
        const foundObject = list.find(
          (item) => item.id === project.workspaceId
        );
        if (foundObject) {
          foundObject.cpuUsage = data.cpuUsage;
          foundObject.memoryUsage = data.memoryUsage;
          foundObject.diskUsage = data.diskUsage;
        } else {
          list.push({ id: project.workspaceId, ...data });
        }
        setResourceUsage(list);
        setCurrentResourceUsage(data);
      })
      .catch((e) => console.error(e));
  }, [project.running, project.workspaceId, resourceUsage, setResourceUsage]);

  return (
    <div
      onMouseMove={() => setIsShowButton(true)}
      onMouseLeave={() => setIsShowButton(false)}
    >
      <CardLayout
        key={project.workspaceId}
        className={cn(
          'relative h-full border-[rgba(255,255,255,0.01)] bg-[#20242B]'
        )}
        onClick={() => {
          if (isInManage) {
            toggleProjectCheckedState();
          }
        }}
      >
        {isInManage && (
          <div
            className={cn(
              'absolute left-0 top-0 z-[2] h-full w-full rounded-[4px] border-[1px] bg-bgTertiaryColor  opacity-60',
              lodash.includes(selectProjects, project)
                ? 'border-themeColor'
                : 'border-[#2C3243]'
            )}
          />
        )}
        {project.thumbnail ? (
          <div className="rounded-t-[6px] bg-[#1C1C1E]">
            <div
              className="h-[165px] w-[100%] rounded-t-[6px]"
              style={{
                background: `url(${project.thumbnail}) no-repeat`,
                backgroundSize: '100% auto',
                transitionDuration: '300ms',
                transitionTimingFunction: 'ease-in-out',
                width: '100%',
                transform: 'scale(1)'
              }}
            />
          </div>
        ) : (
          <div className="flex h-[165px] w-[100%] items-center justify-center rounded-t-[6px] bg-[#1C1C1E]">
            <ProjectPreview className=" h-[33px] w-[115px]" />
          </div>
        )}

        <div
          className="absolute left-0 top-0 h-full w-full "
          onClick={openProject}
        >
          <div className="item-center flex justify-between">
            <div
              className={cn(
                'inline-block rounded-tl-[6px] rounded-br-[6px] bg-[#0961AB] px-[10px] py-1 text-[12px] uppercase text-[#FFFFFF]'
              )}
            >
              {i18n.t('project')}
            </div>
            <p className="px-4 py-1 text-xs leading-[1.5] text-[#95A0AB]">
              {dayjs(Number(project.lastActiveTime))
                .locale(i18n.language === 'en-US' ? 'en-us' : 'zh-cn')
                .fromNow()}
            </p>
          </div>

          {isShowButton && (
            <div className="absolute left-0 bottom-[50px] flex w-full gap-[2px]">
              <ProgressBar
                className="flex w-1/3 flex-col items-center"
                label="CPU"
                process={parseFloat(currentResourceUsage.cpuUsage) || 0}
                totalProcess={parseFloat(currentResourceUsage.cpuTotal) || 250}
                processColor="#22FFD8"
                totalProcessColor="#22FFD826"
              />
              <ProgressBar
                className="flex w-1/3 flex-col items-center"
                label="RAM"
                process={parseFloat(currentResourceUsage.memoryUsage) || 0}
                totalProcess={
                  parseFloat(currentResourceUsage.memoryTotal) || 250
                }
                processColor="#28AEF3"
                totalProcessColor="#28AEF326"
              />
              <ProgressBar
                className="flex w-1/3 flex-col items-center"
                label="STORAGE"
                process={currentResourceUsage.diskUsage}
                totalProcess={currentResourceUsage.diskTotal}
                processColor="#F2C8ED"
                totalProcessColor="#F2C8ED26"
              />
            </div>
          )}
        </div>

        <div className="px-4 py-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-x-1">
              <div className="relative flex h-[26px] w-[26px] items-center justify-center rounded-[2px] bg-[#33364A]">
                <ChainIcon
                  chain={project.fromChain}
                  className="h-[18px] w-[18px]"
                />
                {project.running && (
                  <div className="absolute right-0 bottom-0 flex h-2 w-2 items-center justify-center rounded-full bg-bgPrimaryColor">
                    <div className="h-[6px] w-[6px] animate-pulse rounded-full bg-[#22FFD8]"></div>
                  </div>
                )}
              </div>
              <span className="mt-[2px] text-[14px] font-[400] leading-[1] line-clamp-1">
                {project.workspaceName}
              </span>
            </div>

            {!isInManage && userInfo.userId && (
              <ActionDot type={EActionDotType.Project} project={project} />
            )}
          </div>

          {/* <div className="my-2 h-[21px] font-Poppins text-[#939ABB] line-clamp-1">
            {project.description}
          </div> */}

          {isInManage && (
            <div className="absolute right-[10px] top-[10px] z-[5]">
              {isChecked ? <CheckedSVG /> : <UnCheckedSVG />}
            </div>
          )}
        </div>
      </CardLayout>
    </div>
  );
}
