import React, { createContext, useState } from 'react';

import { IResourceUsage } from '@/services/portForwards/IPortForwardService';
import { IProject } from '@/services/projectService/IProjectService';
import { ITemplate } from '@/services/templateService/ITemplateService';
import { IUserInfo } from '@/utils/tokenUtils';

interface UserContext {
  userInfo: IUserInfo;
  setUserInfo: React.Dispatch<React.SetStateAction<IUserInfo>>;
  projectList: IProject[];
  setProjectList: React.Dispatch<React.SetStateAction<IProject[]>>;
  reloadProjectListNumber: number;
  setReloadProjectListNumber: React.Dispatch<React.SetStateAction<number>>;
  publicTemplateList: ITemplate[];
  setPublicTemplateList: React.Dispatch<React.SetStateAction<ITemplate[]>>;
  privateTemplateList: ITemplate[];
  setPrivateTemplateList: React.Dispatch<React.SetStateAction<ITemplate[]>>;

  selectProjects: IProject[];
  setSelectProjects: React.Dispatch<React.SetStateAction<IProject[]>>;
  selectTemplates: ITemplate[];
  setSelectTemplates: React.Dispatch<React.SetStateAction<ITemplate[]>>;

  resourceUsage: EResourceUsage[];
  setResourceUsage: React.Dispatch<React.SetStateAction<EResourceUsage[]>>;
}

interface PropsType {
  children?: JSX.Element;
}

export const initUserInfo: IUserInfo = {
  userId: '',
  username: '',
  authorities: [],
  blocksecAccessKey: '',
  email: '',
  githubToken: '',
  loginType: ''
};

interface EResourceUsage extends IResourceUsage {
  id: string;
}

export const UserContext = createContext({
  userInfo: initUserInfo,
  projectList: [],
  publicTemplateList: [],
  privateTemplateList: [],
  selectProjects: [],
  selectTemplates: [],
  resourceUsage: [],
  reloadProjectListNumber: 0,
  setReloadProjectListNumber() {
    //
  },
  setProjectList() {
    //
  },
  setUserInfo() {
    //
  },
  setPublicTemplateList() {
    //
  },
  setPrivateTemplateList() {
    //
  },
  setSelectProjects() {
    //
  },
  setSelectTemplates() {
    //
  },
  setResourceUsage() {
    //
  }
} as UserContext);

export const UserProvider = (props: PropsType) => {
  const [userInfo, setUserInfo] = useState<IUserInfo>(initUserInfo);
  const [projectList, setProjectList] = useState<IProject[]>([]);
  const [publicTemplateList, setPublicTemplateList] = useState<ITemplate[]>([]);
  const [privateTemplateList, setPrivateTemplateList] = useState<ITemplate[]>(
    []
  );
  const [selectProjects, setSelectProjects] = useState<IProject[]>([]);
  const [selectTemplates, setSelectTemplates] = useState<ITemplate[]>([]);
  const [resourceUsage, setResourceUsage] = useState<EResourceUsage[]>([]);
  const [reloadProjectListNumber, setReloadProjectListNumber] =
    useState<number>(0);

  return (
    <UserContext.Provider
      value={{
        userInfo,
        setUserInfo,
        projectList,
        setProjectList,
        publicTemplateList,
        setPublicTemplateList,
        privateTemplateList,
        setPrivateTemplateList,
        selectProjects,
        setSelectProjects,
        selectTemplates,
        setSelectTemplates,
        resourceUsage,
        setResourceUsage,
        reloadProjectListNumber,
        setReloadProjectListNumber
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};
