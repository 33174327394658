import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useClickAway } from 'react-use';

import { useTranslation } from '@/i18n';

export interface IOption<T = string> {
  value: T;
  label: string;
}
interface ISelectProps<T = string> {
  className?: string;
  selectOption?: IOption<T>;
  onChange: (option: IOption<T>) => void;
  options: Array<IOption<T>>;
}
export function Select<T = string>(props: ISelectProps<T>) {
  const { className, selectOption, onChange, options } = props;
  const [activeValue, setActiveValue] = useState<T>();
  const ref = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();

  const [show, setShow] = useState(false);

  useEffect(() => {
    if (selectOption?.value) {
      setActiveValue(selectOption.value);
    }
  }, [selectOption]);

  useClickAway(ref, () => {
    setShow(false);
  });
  return (
    <div
      className={cn(className, 'relative select-none')}
      ref={ref}
      onClick={() => {
        setShow(!show);
      }}
    >
      <div className="box-content flex shrink-0 cursor-pointer select-none items-center justify-between rounded-[8px] border-[1px] border-[#5F6D7E66] py-1 px-[10px] text-[16px]">
        <span>{t(selectOption?.label || '')}</span>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.9999 7.99545C16.0004 8.12349 15.9721 8.25 15.9172 8.36568C15.8624 8.48137 15.7823 8.58329 15.6828 8.66395L10.5405 12.8036C10.3871 12.9296 10.1947 12.9985 9.99622 12.9985C9.7977 12.9985 9.60534 12.9296 9.45199 12.8036L4.30962 8.51825C4.13459 8.37278 4.02452 8.16373 4.00363 7.9371C3.98273 7.71047 4.05272 7.48482 4.1982 7.3098C4.34367 7.13477 4.55272 7.0247 4.77935 7.00381C5.00598 6.98291 5.23163 7.0529 5.40665 7.19838L10.0005 11.0294L14.5944 7.32694C14.7202 7.22213 14.8734 7.15556 15.0358 7.13509C15.1983 7.11463 15.3632 7.14113 15.5111 7.21145C15.6589 7.28178 15.7835 7.39299 15.8702 7.53193C15.9568 7.67087 16.0019 7.83172 15.9999 7.99545Z"
            fill="white"
            className={cn('origin-center duration-300', show && 'rotate-180')}
          />
        </svg>
      </div>

      <ul
        className={cn(
          'absolute top-11 w-full select-none flex-col gap-y-1 overflow-y-auto rounded-common bg-[#2D343E] text-sm duration-300',
          show ? 'z-10 flex opacity-100 shadow-common' : '-z-10 opacity-0'
        )}
      >
        {options.map((item) => {
          return (
            <li
              key={String(item.value)}
              onClick={() => {
                onChange(item);
              }}
              className={cn(
                'flex h-8 shrink-0 cursor-pointer items-center gap-x-[6px] overflow-y-auto pl-4 text-[13px] hover:bg-[#3D4755]',
                item.value === activeValue && 'bg-[#3D4755]'
              )}
            >
              <span>{t(item.label)}</span>
            </li>
          );
        })}
      </ul>
      {/* <ul
        className={cn(
          'absolute bottom-0 right-0 h-0 w-[calc(100%_+_10px)] translate-y-[calc(100%_+_4px)] overflow-hidden rounded-lg bg-[black] opacity-0 shadow-common duration-300 group-hover:opacity-100',
          options.length === 1 && 'group-hover:h-8',
          options.length === 2 && 'group-hover:h-16',
          options.length === 3 && 'group-hover:h-24',
          options.length === 4 && 'group-hover:h-32',
          options.length === 5 && 'group-hover:h-40',
          options.length === 6 && 'group-hover:h-48',
          options.length === 7 && 'group-hover:h-56',
          options.length === 8 && 'group-hover:h-64',
          options.length === 9 && 'group-hover:h-72',
          options.length === 10 && 'group-hover:h-80',
          options.length === 11 && 'group-hover:h-88',
          options.length === 12 && 'group-hover:h-96'
        )}
      >
        {options.map((item) => (
          <li
            onMouseEnter={() => {
              setActiveValue(item.value);
            }}
            key={String(item.value)}
            className={cn(
              'h-8 px-4 leading-8',
              item.value === activeValue && 'bg-bgTertiaryColor'
            )}
            onClick={() => {
              onChange(item);
            }}
          >
            {item.label}
          </li>
        ))}
      </ul> */}
    </div>
  );
}
