/* eslint-disable node/no-unsupported-features/node-builtins */
import { navigate } from 'gatsby';

export function useSearchUpdate() {
  const putRouterParams = (params: Record<string, string>) => {
    const searchParams = new URLSearchParams(location.search);
    for (const key in params) {
      const value = params[key];
      if (value === '') {
        searchParams.delete(key);
      } else {
        searchParams.set(key, params[key]);
      }
    }

    void navigate(
      `${
        location.host === 'localhost'
          ? location.pathname
          : location.pathname.replace('dashboard/', '')
      }?${searchParams.toString()}`,
      {
        replace: true
      }
    );
  };

  const deleteRouterParams = (keys: string[]) => {
    const searchParams = new URLSearchParams(location.search);
    for (const key of keys) {
      searchParams.delete(key);
    }
    const hasSearchKey = !!searchParams.size;
    void navigate(
      `${
        location.host === 'localhost'
          ? location.pathname
          : location.pathname.replace('dashboard/', '')
      }${hasSearchKey ? '?' : ''}${searchParams.toString()}`,
      { replace: true }
    );
  };

  return { putRouterParams, deleteRouterParams };
}
