import cn from 'classnames';
import React, { ReactNode } from 'react';
interface ICardLayoutProps {
  children: ReactNode;
  className?: string;
  onClick?: () => void;
}

export function CardLayout(props: ICardLayoutProps) {
  const { children, className, onClick } = props;
  return (
    <div
      onClick={onClick}
      className={cn(
        className,
        'flex w-full cursor-pointer flex-col justify-start rounded-[6px] border-[1px] border-solid'
      )}
    >
      {children}
    </div>
  );
}
