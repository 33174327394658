import cn from 'classnames';
import React from 'react';
import ReactSkeleton, { SkeletonProps } from 'react-loading-skeleton';
interface IReactSkeletonProps extends SkeletonProps {
  className?: string;
  containerClassName?: string;
}
export function Skeleton(props: IReactSkeletonProps) {
  const { containerClassName, className } = props;
  return (
    <div className={cn(className, containerClassName)}>
      <ReactSkeleton {...props} />
    </div>
  );
}
