import cn from 'classnames';
import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState
} from 'react';
import { useClickAway, useToggle } from 'react-use';

import DeleteSVG from '@/assets/images/common/delete.inline.svg';
import SaveAsTemplateSVG from '@/assets/images/common/save_as_template.inline.svg';
import SetAsDefaultSVG from '@/assets/images/common/set_as_default.inline.svg';
import UseTemplateSVG from '@/assets/images/common/use_template.inline.svg';
import EditSVG from '@/assets/images/project/edit.inline.svg';
import ShutDownSVG from '@/assets/images/project/shutdown.inline.svg';
import { useTranslation } from '@/i18n';
import { DialogContext } from '@/libs/dialogClient/dialogProvider';
import { UserContext } from '@/libs/userClient/userProvider';
import { projectService } from '@/services/projectService';
import { IProject } from '@/services/projectService/IProjectService';
import sandboxService from '@/services/sandboxService';
import { ITemplate } from '@/services/templateService/ITemplateService';
import { useGetDeviceId } from '@/utils/hooks/useGetDeviceId';

function Dot() {
  return (
    <div className="h-[2px] min-h-[2px] w-[2px] min-w-[2px] shrink-0 rounded-full bg-[#ebedf1]"></div>
  );
}

export enum EActionDotType {
  Project = 'Project',
  PrivateTemplate = 'PrivateTemplate',
  PublicTemplate = 'PublicTemplate'
}

interface IActionDotProps {
  type: EActionDotType;
  project?: IProject;
  template?: ITemplate;
}

export function ActionDot(props: IActionDotProps) {
  const { project, type, template } = props;
  const [showDrop, toggleShowDrop] = useToggle(false);
  const { deviceId } = useGetDeviceId();
  const { i18n } = useTranslation();
  const ref = useRef<HTMLDivElement | null>(null);
  useClickAway(ref, () => {
    toggleShowDrop(false);
  });
  const { setIsDeleteProjects, setIsOpenEditDialog, setIsOpenSaveAsTemplate } =
    useContext(DialogContext);
  const {
    setSelectProjects,
    setSelectTemplates,
    reloadProjectListNumber,
    setReloadProjectListNumber
  } = useContext(UserContext);

  const [sandboxStatus, setSandboxStatus] = useState<string>('');

  const handelSandboxClick = useCallback(async () => {
    if (!project) return;
    try {
      setSandboxStatus('Pending');
      if (project.running) {
        await sandboxService.shutdownSandbox(
          project?.workspaceId ?? template?.id ?? ''
        );
      } else {
        await sandboxService.startupSandbox(
          project?.workspaceId ?? template?.id ?? ''
        );
      }

      let success = false;
      let attempts = 0;

      while (!success && attempts < 5) {
        try {
          const response = await sandboxService.getSandboxStatus(
            project.workspaceId
          );

          if (response.status === 'Running' || response.status === 'Sleeping') {
            success = true;
            setSandboxStatus(response.status);
          } else {
            attempts++;
            await new Promise((resolve) => setTimeout(resolve, 1000));
          }
        } catch (error) {
          console.error('Error fetching data:', error);
          attempts++;
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }
      }

      setTimeout(() => {
        setReloadProjectListNumber(reloadProjectListNumber + 1);
      }, 1000);
    } catch (e) {
      console.error(e);
    }
  }, [
    project,
    reloadProjectListNumber,
    setReloadProjectListNumber,
    template?.id
  ]);

  const sandboxButtonText = useMemo(() => {
    if (sandboxStatus === 'Pending') {
      return 'sandbox_pending';
    }
    if (!project?.running) {
      return 'sandbox_start_up';
    }

    if (project?.running) {
      return 'sandbox_shut_down';
    }

    return 'sandbox_start_up';
  }, [project?.running, sandboxStatus]);

  const createProjectByPrivateTemplate = useCallback(async () => {
    if (!template) return;
    try {
      const projectId = await projectService.createProjectByTemplate(
        deviceId,
        template.chain,
        template.id
      );
      location.href = `${location.origin}/studio/?id=${projectId}&chain=${template.chain}`;
    } catch (e) {
      console.log(e);
    }
  }, [deviceId, template]);

  return (
    <div
      ref={ref}
      onClick={(event) => {
        if (sandboxStatus === 'Pending') {
          toggleShowDrop(true);
        } else {
          toggleShowDrop();
        }
        event.stopPropagation();
      }}
      className="relative flex h-6 w-6 items-center justify-center gap-x-[2px] rounded-[2px] bg-[#2E343F]"
    >
      <Dot />
      <Dot />
      <Dot />
      <ul
        className={cn(
          'absolute top-[26px] -right-[1px] z-[10] w-[204px] overflow-hidden rounded-common bg-[#2E343F] shadow-common transition-[height] duration-300',
          !showDrop
            ? 'h-0'
            : type === EActionDotType.Project
            ? `h-[${
                project?.running ? '176px' : '132px'
              }] border-[1px] border-solid border-cardBorderColor`
            : 'h-[132px] border-[1px] border-solid border-cardBorderColor '
        )}
      >
        {type === EActionDotType.Project && project?.running && (
          <li
            className="border-b-solid box-border flex h-11 items-center gap-x-[6px] border-b-[1px] border-b-splitLineColor  px-4 text-textSecondaryColor duration-300 hover:text-themeColorHeavy"
            onClick={handelSandboxClick}
          >
            <ShutDownSVG />
            {/* todo:待验证页面中文是否正确 */}
            <span>{i18n.t(sandboxButtonText)}</span>
          </li>
        )}

        {type !== EActionDotType.Project && (
          <li
            className="border-b-solid box-border flex h-11 items-center gap-x-[6px]  px-4 text-textSecondaryColor duration-300 hover:text-themeColorHeavy"
            onClick={createProjectByPrivateTemplate}
          >
            <UseTemplateSVG />
            {/* todo:待验证页面中文是否正确 */}
            <span>{i18n.t('use_template')}</span>
          </li>
        )}

        <li
          className="flex h-11 items-center gap-x-[6px] px-4 text-textSecondaryColor duration-300 hover:text-themeColorHeavy"
          onClick={() => {
            if (project) {
              setSelectProjects([project]);
            }
            if (template) {
              setSelectTemplates([template]);
            }
            setIsOpenEditDialog(true);
          }}
        >
          <EditSVG />
          <span>
            {i18n.t('edit')} {type !== EActionDotType.Project ? 'Template' : ''}
          </span>
        </li>
        {type === EActionDotType.Project && (
          <li
            className="flex h-11 items-center gap-x-[6px] px-4 text-textSecondaryColor duration-300 hover:text-themeColorHeavy "
            onClick={() => {
              if (project) {
                setSelectProjects([project]);
              }
              if (template) {
                setSelectTemplates([template]);
              }
              setIsOpenSaveAsTemplate(true);
            }}
          >
            <SaveAsTemplateSVG />
            <span>{i18n.t('save_as_template')}</span>
          </li>
        )}
        <li
          className="flex h-11 items-center gap-x-[6px] px-4 text-textSecondaryColor duration-300 hover:text-themeColorHeavy "
          onClick={() => {
            if (project) {
              setSelectProjects([project]);
            }
            if (template) {
              setSelectTemplates([template]);
            }
            setIsDeleteProjects(true);
          }}
        >
          <DeleteSVG />
          <span>
            {i18n.t('delete')}{' '}
            {type !== EActionDotType.Project ? 'Template' : ''}
          </span>
        </li>

        {type === EActionDotType.PublicTemplate && (
          <li
            className="flex h-11 items-center gap-x-[6px] px-4 text-textSecondaryColor duration-300 hover:text-themeColorHeavy "
            onClick={() => {
              if (project) {
                setSelectProjects([project]);
              }
              if (template) {
                setSelectTemplates([template]);
              }
              setIsDeleteProjects(true);
            }}
          >
            <SetAsDefaultSVG />
            <span>{i18n.t('set_as_default')}</span>
          </li>
        )}
      </ul>
    </div>
  );
}
