import './styles.css';

import cn from 'classnames';
import { navigate } from 'gatsby';
import React, { ReactNode } from 'react';
import { toast } from 'react-toastify';
import { useAsyncFn } from 'react-use';

import MethodBlankSVG from '@/assets/images/project/method_blank.inline.svg';
import MethodFileSVG from '@/assets/images/project/method_file.inline.svg';
import MethodGithubSVG from '@/assets/images/project/method_github.inline.svg';
import MethodPrivateTemplateSVG from '@/assets/images/project/method_private_template.inline.svg';
import MethodPublicTemplateSVG from '@/assets/images/project/method_public_template.inline.svg';
import { Button, EButtonType } from '@/components';
import { useTranslation } from '@/i18n';
import { projectService } from '@/services/projectService';
import templateService from '@/services/templateService';
import { Locale } from '@/services/templateService/ITemplateService';
import { EChainType } from '@/utils/const';
import { useGetDeviceId } from '@/utils/hooks/useGetDeviceId';
export enum ECreateProjectMethodType {
  BASIC_TEMPLATE = 'start_with_basic_configuration',
  PUBLIC_TEMPLATE = 'choose_public_template',
  PRIVATE_TEMPLATE = 'choose_private_template',
  IMPORT_FROM_GITHUB = 'Github',
  IMPORT_FROM_GIST = 'Gist',
  IMPORT_FROM_FILE = 'file',
  DEFAULT = 'Default'
}

interface ICreateMethodSelectorProps {
  animationKey: number;
  chain: EChainType;
  methodType: ECreateProjectMethodType;
  setMethodType: (type: ECreateProjectMethodType) => void;
  setAnimationKey: React.Dispatch<React.SetStateAction<number>>;
}
export function CreateMethodSelector(props: ICreateMethodSelectorProps) {
  const { methodType, setMethodType, animationKey, setAnimationKey, chain } =
    props;
  const { deviceId } = useGetDeviceId();
  const { i18n, t } = useTranslation();

  const [createProjectByBasicTemplateStatus, doCreateProjectByBasicTemplate] =
    useAsyncFn(async () => {
      setAnimationKey(animationKey + 1);
      try {
        const templateList = await templateService.getTemplates(
          i18n.language === 'en-US' ? Locale.EN : Locale.ZH,
          true,
          !chain || chain === 'all' ? undefined : chain
        );
        const defaultTemplate = templateList.find((item) => item.isDefault);
        if (!defaultTemplate) {
          toast.error(t('get_default_template_error'));
          return;
        }

        const projectId = await projectService.createProjectByTemplate(
          deviceId,
          chain,
          defaultTemplate.id
        );
        location.href = `${location.origin}/studio/?id=${projectId}&chain=${chain}`;
      } catch (e) {
        toast.error(t('create_project_error'));
        console.log({ e });
      }
    }, [animationKey, chain, deviceId, i18n.language, setAnimationKey, t]);

  return (
    <div className=" rounded-common bg-[#20242B] px-5 py-4">
      <h3>{i18n.t('use_a_template')}</h3>
      <div className="flex flex-col gap-y-4">
        <div>
          <div
            className={cn(
              'basicTemplate mt-5 mb-4 h-auto rounded-[6px] border-[1px] border-[#FFFFFF0D] bg-[#24282f] hover:border-[#3272C7] sm:py-5'
            )}
          >
            <div className="w-full px-5 pb-2 text-start font-IBMPlexSans">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-x-2">
                  <MethodBlankSVG />
                  <span>{i18n.t(ECreateProjectMethodType.BASIC_TEMPLATE)}</span>
                </div>

                <Button
                  className="basicButton float-right !rounded-[6px] border-[1px] border-[#3272C7] bg-transparent py-1 px-8 opacity-0 hover:bg-[#3272C7]"
                  loading={createProjectByBasicTemplateStatus.loading}
                  onClick={doCreateProjectByBasicTemplate}
                >
                  {i18n.t('start_button_text')}
                </Button>
              </div>

              <div className="mt-3 whitespace-normal pl-1 text-[13px] text-[#95A0AB]">
                {i18n.t('start_with_basic_configuration_des')}
              </div>
            </div>
          </div>
          <div
            className={cn(
              'publicTemplate mt-5 mb-4 h-auto rounded-[6px] border-[1px] border-[#FFFFFF0D] bg-[#24282f] hover:border-[#3272C7] sm:py-5'
            )}
          >
            <div className="w-full px-5 pb-2 text-start font-IBMPlexSans">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-x-2">
                  <MethodPublicTemplateSVG />
                  <span>
                    {i18n.t(ECreateProjectMethodType.PUBLIC_TEMPLATE)}
                  </span>
                </div>

                <Button
                  className="basicButton float-right !rounded-[6px] border-[1px] border-[#3272C7] bg-transparent py-1 px-8 opacity-0 hover:bg-[#3272C7]"
                  onClick={() => {
                    setAnimationKey(animationKey + 1);
                    void navigate('/templates');
                  }}
                >
                  {i18n.t('choose_button_text')}
                </Button>
              </div>

              <div className="mt-3 whitespace-normal pl-1 text-[13px] text-[#95A0AB]">
                {i18n.t('choose_public_template_des')}
              </div>
            </div>
          </div>
          <div
            className={cn(
              'privateTemplate mt-5 h-auto rounded-[6px] border-[1px] border-[#FFFFFF0D] bg-[#24282f] hover:border-[#3272C7] sm:py-5'
            )}
          >
            <div className="w-full px-5 pb-2 text-start font-IBMPlexSans">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-x-2">
                  <MethodPrivateTemplateSVG />
                  <span>
                    {i18n.t(ECreateProjectMethodType.PRIVATE_TEMPLATE)}
                  </span>
                </div>

                <Button
                  className="basicButton float-right !rounded-[6px] border-[1px] border-[#3272C7] bg-transparent py-1 px-8 opacity-0 hover:bg-[#3272C7]"
                  onClick={() => {
                    setMethodType(ECreateProjectMethodType.PRIVATE_TEMPLATE);
                    setAnimationKey(animationKey + 1);
                  }}
                >
                  {i18n.t('choose_button_text')}
                </Button>
              </div>

              <div className="mt-3 w-full whitespace-normal pl-1 text-[13px] text-[#95A0AB]">
                {i18n.t('choose_private_template_des')}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-[10px] flex w-full items-center gap-x-[10px]">
          <div className="h-[1px] w-[50%] border-t-[1px] border-dashed border-[#FFFFFF26]" />
          <span className="justify-center whitespace-nowrap text-center">
            {i18n.t('or_import')}
          </span>
          <div className="h-[1px] w-[50%] border-t-[1px] border-dashed border-[#FFFFFF26]" />
        </div>
        <div className="flex w-full flex-col gap-4 sm:flex-row">
          <MethodButton
            onClick={() => {
              setMethodType(ECreateProjectMethodType.IMPORT_FROM_GITHUB);
              setAnimationKey(animationKey + 1);
            }}
            isActive={
              methodType === ECreateProjectMethodType.IMPORT_FROM_GITHUB
            }
            className={cn(
              'border-[1px] border-[#FFFFFF0D] bg-[#24282f] hover:border-[#3272C7] sm:py-5'
            )}
          >
            <div className="flex items-center gap-x-2 font-medium">
              <MethodGithubSVG />
              <span>{ECreateProjectMethodType.IMPORT_FROM_GITHUB}</span>
            </div>
          </MethodButton>
          <MethodButton
            onClick={() => {
              setMethodType(ECreateProjectMethodType.IMPORT_FROM_GIST);
              setAnimationKey(animationKey + 1);
            }}
            isActive={methodType === ECreateProjectMethodType.IMPORT_FROM_GIST}
            className={cn(
              'border-[1px] border-[#FFFFFF0D] bg-[#24282f] hover:border-[#3272C7] sm:py-5'
            )}
          >
            <div className="flex items-center gap-x-2 font-medium">
              <MethodGithubSVG />
              <span>{ECreateProjectMethodType.IMPORT_FROM_GIST}</span>
            </div>
          </MethodButton>
          <MethodButton
            onClick={() => {
              setMethodType(ECreateProjectMethodType.IMPORT_FROM_FILE);
              setAnimationKey(animationKey + 1);
            }}
            isActive={methodType === ECreateProjectMethodType.IMPORT_FROM_FILE}
            className={cn(
              'border-[1px] border-[#FFFFFF0D]  bg-[#24282f] hover:border-[#3272C7] sm:py-5'
            )}
          >
            <div className="flex items-center gap-x-2 font-medium">
              <MethodFileSVG />
              <span>{i18n.t(ECreateProjectMethodType.IMPORT_FROM_FILE)}</span>
            </div>
          </MethodButton>
        </div>
      </div>
    </div>
  );
}

interface IMethodButtonProps {
  className?: string;
  isActive: boolean;
  children: ReactNode;
  onClick?: () => void;
  loading?: boolean;
}
function MethodButton(props: IMethodButtonProps) {
  const { className, isActive, children, onClick, loading = false } = props;
  return (
    <Button
      onClick={onClick}
      loading={loading}
      type={EButtonType.PRIMARY}
      className={cn('h-10 w-full cursor-pointer hover:!opacity-90', className)}
    >
      {children}
    </Button>
  );
}
