import cn from 'classnames';
import React, { useRef, useState } from 'react';
import { useClickAway } from 'react-use';

import ShareSVG from '@/assets/images/sidebar/share.inline.svg';
import SupportSVG from '@/assets/images/sidebar/support.inline.svg';
import SupportChatbotSVG from '@/assets/images/sidebar/support_chatbot.inline.svg';
import SupportDocSVG from '@/assets/images/sidebar/support_doc.inline.svg';
import SupportFeedbackSVG from '@/assets/images/sidebar/support_feedback.inline.svg';
import SupportForumSVG from '@/assets/images/sidebar/support_forum.inline.svg';
import SupportUpdateSVG from '@/assets/images/sidebar/support_update.inline.svg';
import { switchLanguage, useTranslation } from '@/i18n';

const data = [
  {
    icon: SupportChatbotSVG,
    title: 'chatbot_title',
    desc: 'support_chatbot_desc',
    link: 'https://chainide.com'
  },
  {
    icon: SupportDocSVG,
    title: 'doc_title',
    desc: 'support_doc_desc',
    link: 'https://chainide.com'
  },
  {
    icon: SupportForumSVG,
    title: 'forum_title',
    desc: 'support_forum_desc',
    link: 'https://chainide.com'
  },
  {
    icon: SupportFeedbackSVG,
    title: 'feedback_title',
    desc: `support_feedback_desc`,
    link: 'https://chainide.com'
  },
  {
    icon: SupportUpdateSVG,
    title: 'update_title',
    desc: `support_update_desc`,
    link: 'https://chainide.com'
  }
];
export function Support() {
  const { i18n } = useTranslation();
  const ref = useRef<HTMLDivElement | null>(null);
  const [show, setShow] = useState(false);
  useClickAway(ref, () => {
    setShow(false);
  });
  return (
    <div
      className="relative"
      ref={ref}
      // onMouseEnter={() => {
      //   setShow(true);
      // }}
      // onMouseLeave={() => {
      //   setShow(false);
      // }}
    >
      <div
        onClick={() => {
          setShow(!show);
        }}
        className="flex h-8 w-full shrink-0 cursor-pointer items-center gap-x-1 rounded-common bg-[#20242B] pl-4 pr-[10px] text-[#B1B2B5]"
      >
        <SupportSVG />
        <span>{i18n.t('support')}</span>
      </div>
      <ul
        className={cn(
          'absolute -top-1 max-h-[calc(100vh_-_160px)] w-[calc(100vw_-_32px)] max-w-[372px] -translate-y-full cursor-pointer overflow-y-auto rounded-common bg-[#2E343F] text-sm shadow-common',
          show ? 'z-20 opacity-100' : '-z-10 opacity-0'
        )}
      >
        {data.map((item) => (
          <a href={item.link} target="_blank" key={item.title} rel="noreferrer">
            <li className="px-3 py-2 hover:bg-bgDropdownHoverColor sm:px-4 sm:py-2">
              <div className="mb-1 flex items-center gap-x-1">
                <item.icon className="mb-1" />
                <span className="font-medium">{i18n.t(item.title)}</span>
              </div>
              <p className="pl-6 pr-4 align-middle leading-[1.3] text-textTertiaryColor">
                {i18n.t(item.desc)}
                <ShareSVG className="ml-1 inline-block" />
              </p>
            </li>
          </a>
        ))}
      </ul>
    </div>
  );
}
